
export interface ReceiveContainerInput {
    containerId: string;
    warehouseId: string;
    associate: string;
    locale?: string;
}

// No Output
export interface ReceiveContainerOutput {

}

export const buildReceiveContainerInput = (containerId: string, warehouseId: string, associate: string): ReceiveContainerInput => {
    return {
        containerId: containerId,
        warehouseId: warehouseId,
        associate: associate
    }
}