import { ContainerDetailsModel } from "src/components/common/container-details/ContainerDetailsModel";

export enum GetContainerMode {
    IDENTIFICATION = 'IDENTIFICATION',
    PRINT = 'PRINT'
}

export interface GetContainerDetailsInput {
    containerId: string;
    associate: string;
    warehouseId: string;
    includeChildren: boolean;
    mode?: GetContainerMode;
    locale?: string;
}

export interface GetContainerDetailsOutput {
    container: ContainerDetailsModel;
    childContainers: ContainerDetailsModel[];
}

export const buildGetContainerDetailsInput = (containerId: string, 
    warehouseId: string, associate: string, locale: string): GetContainerDetailsInput => {
    return {
        containerId: containerId,
        // TODO: update these when BFF changes are complete to read associate & warehouseId
        warehouseId: warehouseId,
        associate: associate,
        includeChildren: false,
        mode: GetContainerMode.IDENTIFICATION,
        locale: locale
    }

}

export const buildGetContainerDetailsPrintModeInput = (containerId: string, warehouseId: string, 
    associate: string, locale: string): GetContainerDetailsInput => {
    return {
        containerId: containerId,
        // TODO: update these when BFF changes are complete to read associate & warehouseId
        warehouseId: warehouseId,
        associate: associate,
        includeChildren: false,
        mode: GetContainerMode.PRINT,
        locale: locale
    }
}