import * as AppStrings from 'src/localization/string-ids/app-strings';
import {ContainerType} from 'src/components/common/container-details/ContainerDetailsModel';

export interface ContainerDetailsToStringIDMap {
    containerId: string;
    warehouseId: string;
    sortationCategories: string;
    parentContainer: string;
    latestProcessingDate: string;
    containerProperties: string;
    latestContainerOperation: string;
    latestContainerOperation_name: string;
    latestContainerOperation_time: string;
    latestContainerOperation_associate: string;
    numOfChildContainers: string;
}

const getContainerIdString = (containerType: ContainerType): string => {
    switch (containerType) {
        case ContainerType.ITEM:
            return AppStrings.CONTAINER_DETAILS_LPN_LABEL;
        case ContainerType.TOTE:
            return AppStrings.CONTAINER_DETAILS_TOTE_ID;
        case ContainerType.PALLET:
            return AppStrings.CONTAINER_DETAILS_PALLET_ID;
        case ContainerType.STACKING_AREA:
            return AppStrings.CONTAINER_DETAILS_STACKING_AREA_ID;
        case ContainerType.DROP_ZONE:
            return AppStrings.CONTAINER_DETAILS_DROP_ZONE_ID;
        case ContainerType.TRAILER:
            return AppStrings.CONTAINER_DETAILS_TRAILER_ID;
        default:
            return AppStrings.CONTAINER_DETAILS_DEFAULT_CONTAINER_ID;
    }
}

const getChildContainersTypeFromContainerType = (containerType: ContainerType): string => {
    switch (containerType) {
        case ContainerType.STACKING_AREA:
            return AppStrings.CONTAINER_DETAILS_TOTES;
        case ContainerType.PALLET:
            return AppStrings.CONTAINER_DETAILS_TOTES;
        case ContainerType.TOTE:
            return AppStrings.CONTAINER_DETAILS_ITEMS;
        default:
            return 'numOfChildContainers:';
    }
}

const createContainerDetailsToStringIDMap = (containerType: ContainerType): ContainerDetailsToStringIDMap => {
    return {
        containerId: getContainerIdString(containerType),
        warehouseId: AppStrings.CONTAINER_DETAILS_WAREHOUSE_ID,
        sortationCategories: AppStrings.CONTAINER_DETAILS_SORTATION_CATEGORIES,
        parentContainer: AppStrings.CONTAINER_DETAILS_PARENT_CONTAINER,
        latestProcessingDate: AppStrings.CONTAINER_DETAILS_LATEST_PROCESSING_DATE,
        containerProperties: AppStrings.CONTAINER_DETAILS_CONTAINER_PROPERTIES,
        latestContainerOperation: AppStrings.CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS,
        latestContainerOperation_name: AppStrings.CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS_NAME,
        latestContainerOperation_time: AppStrings.CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS_TIME,
        latestContainerOperation_associate: AppStrings.CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS_ASSOCIATE,
        numOfChildContainers: getChildContainersTypeFromContainerType(containerType)
    }
}

export default createContainerDetailsToStringIDMap;
