import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import { BatchMoveContainersInput } from 'src/models/batchMoveContainers';
import {
    batchMoveContainers,
    BatchMoveContainersPages,
    resetChildAndDestinationContainers,
    updateDestinationContainerId,
    setPageToRender
} from 'src/store/common-slices/batchMoveContainersSlice';
import DestinationContainerScanPage from 'src/components/pages/destination-container-scan/DestinationContainerScanPage';

const mapStateToProps = (state: RootState) => ({
    childContainers: state.BatchMoveContainers.childContainers,
    destinationContainerId: state.BatchMoveContainers.destinationContainerId,
    batchMoveMode: state.BatchMoveContainers.batchMoveMode,
    uiAlertState: state.UI.alertState,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id 
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    batchMoveContainers: (batchMoveContainersInput: BatchMoveContainersInput) => dispatch(batchMoveContainers(batchMoveContainersInput)),
    updateDestinationContainerId: (destinationContainerId: string) => dispatch(updateDestinationContainerId(destinationContainerId)),
    setPageToRender: (pageToRender: BatchMoveContainersPages) => dispatch(setPageToRender(pageToRender)),
    resetChildAndDestinationContainers: () => dispatch(resetChildAndDestinationContainers())
});

export default connect(mapStateToProps, mapDispatchToProps) (DestinationContainerScanPage);