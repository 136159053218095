import { GetContainerDetailsInput, GetContainerDetailsOutput } from 'src/models/GetContainerDetails';
import { BatchMoveContainersInput, BatchMoveContainersOutput } from 'src/models/batchMoveContainers';
import { CloseContainerInput, CloseContainerOutput } from 'src/models/closeContainer';
import BackendServiceCall from './backend-service-call';
import { ReceiveContainerInput, ReceiveContainerOutput } from 'src/models/receiveContainer';
import { PrintContainerLabelDetailsInput, PrintContainerLabelDetailsOutput } from 'src/models/PrintContainerLabelDetails';
import { ProcessContainerInput, ProcessContainerOutput } from 'src/models/processContainer';
import { InitRequest, InitResponse } from 'src/models/init-model';

/**
 * This class contains methods to trigger downstream API calls
 */
const INIT_URL = '/api/init';
const GET_CONTAINER_URL = '/api/getContainer';
const BATCH_MOVE_CONTAINER_URL = '/api/batchMoveContainers';
const CLOSE_CONTAINER_URL = '/api/closeContainer';
const RECEIVE_CONTAINER_URL = '/api/receiveContainer';
const PRINT_CONTAINER_LABEL_URL = '/api/printContainerLabel';
const PROCESS_CONTAINER_URL = '/api/processContainer';

class RIVBFFService extends BackendServiceCall {
    public static async triggerInitAPI(initRequest: InitRequest) {
        const response: InitResponse = await this.makeApiRequest<InitRequest>(
            initRequest,
            INIT_URL,
            'GET'
        );
        return response;
    }

    public static async triggerGetContainerDetailsAPI(input: GetContainerDetailsInput) {
        const response: GetContainerDetailsOutput = await this.makeApiRequest<GetContainerDetailsInput>(
            input,
            GET_CONTAINER_URL,
            'GET'
        );
        return response;
    }

    public static async triggerBatchMoveContainersAPI(input: BatchMoveContainersInput) {
        const response: BatchMoveContainersOutput = await this.makeApiRequest<BatchMoveContainersInput>(
            input,
            BATCH_MOVE_CONTAINER_URL,
            'POST'
        );
        return response;
    }

    public static async triggerCloseContainerAPI(input: CloseContainerInput) {
        const response: CloseContainerOutput = await this.makeApiRequest<CloseContainerInput>(
            input,
            CLOSE_CONTAINER_URL,
            'POST'
        );
        return response;
    }

    public static async triggerReceiveContainerAPI(input: ReceiveContainerInput) {
        const response: ReceiveContainerOutput = await this.makeApiRequest<ReceiveContainerInput>(
            input,
            RECEIVE_CONTAINER_URL,
            'POST'
        );
        return response;
    }

    public static async triggerPrintContainerLabelDetailsAPI(input: PrintContainerLabelDetailsInput) {
        const response: PrintContainerLabelDetailsOutput = await this.makeApiRequest<PrintContainerLabelDetailsInput>(
            input,
            PRINT_CONTAINER_LABEL_URL,
            'POST'
        );
        return response;
    }

    public static async triggerProcessContainerAPI(input: ProcessContainerInput) {
        const response: ProcessContainerOutput = await this.makeApiRequest<ProcessContainerInput>(
            input,
            PROCESS_CONTAINER_URL,
            'POST'
        );
        return response;
    }
}
 
export default RIVBFFService;