
export interface ProcessContainerInput {
    containerId: string;
    warehouseId: string;
    associate: string;
}

export interface ProcessContainerOutput {

}

export const buildProcessContainerInput = (containerId: string, warehouseId: string, associate: string): ProcessContainerInput => {
    return {
        containerId,
        warehouseId,
        associate
    }
}