import React from 'react';
import AppLayout from "@amzn/meridian/app-layout"
import TopNav from 'src/components/app/top-nav/TopNavContainer';
import Footer from 'src/components/app/footer/FooterContainer';
import AppRouter from 'src/routing/AppRouter';

import './App.scss';

class App extends React.Component {

    public render() {
        return (
            <>
                <AppLayout
                    headerComponent={TopNav}
                    footerComponent={Footer}
                >
                    <TopNav/>
                    <AppRouter/>
                    <Footer />
                </AppLayout>
            </>
        );
    }
}

export default App;

