import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import Containerization from 'src/components/pages/containerization-page/Containerization';
import { resetContainerState } from 'src/store/common-slices/getContainerSlice';
import { resetBatchMoveContainersState } from 'src/store/common-slices/batchMoveContainersSlice';

const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    batchMoveMode: state.BatchMoveContainers.batchMoveMode,
    childContainers: state.BatchMoveContainers.childContainers,
    pageToRender: state.BatchMoveContainers.pageToRender
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetContainerState: () => dispatch(resetContainerState()),
    resetBatchMoveContainersState: () => dispatch(resetBatchMoveContainersState()),
});

export default connect(mapStateToProps, mapDispatchToProps) (Containerization);