import React from 'react';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import './MultiContainerDetails.scss'


interface MultiContainerDetailsProps {
    childContainers: ContainerDetailsModel[];
}

class MultiContainerDetails extends React.Component<MultiContainerDetailsProps> {

    private getTableRows = () => {
        const rows: React.ReactElement[] = this.props.childContainers.map(container => (
            <TableRow key={container.containerId}>
                <TableCell alignmentHorizontal="center">{container.containerId}</TableCell>
                <TableCell alignmentHorizontal="center">{container.sortationCategories}</TableCell>
                <TableCell alignmentHorizontal="center">{container.numOfChildContainers}</TableCell>
            </TableRow>
        ));
        return rows;
    }

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props =>(
                    <Table headerRows={2} showDividers={true} className='multi-mode-container-details'>
                        <TableRow>
                            <TableCell columnSpan={3}>{AppStrings.MULTI_CONTAINER_DETAILS_SCANNED_CONTAINERS}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell alignmentHorizontal={'center'}>{`${AppStrings.MULTI_CONTAINER_DETAILS_HEADER_QUANTITY}: ${this.props.childContainers.length}`}</TableCell>
                            <TableCell alignmentHorizontal={'center'}>{AppStrings.MULTI_CONTAINER_DETAILS_HEADER_CATEGORY}</TableCell>
                            <TableCell alignmentHorizontal={'center'}>{AppStrings.MULTI_CONTAINER_DETAILS_HEADER_CHILD_CONTAINERS}</TableCell>
                        </TableRow>

                        {this.getTableRows()}
                    </Table>
                )}
            </Responsive>
        )
    }
}

export default MultiContainerDetails;
