import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProcessContainerInput, ProcessContainerOutput } from 'src/models/processContainer';
import { resetIsLoading, resetUiAlertState, setIsLoading, setUiAlertState } from 'src/store/common-slices/uiSlice';
import RIVBFFService from 'src/services/riv-bff-service';
import { AlertLevels } from 'src/models/alert-model';
import * as ErrorConstants from 'src/localization/string-ids/error-constants';
import * as SuccessConstants from 'src/localization/string-ids/success-constants';

export enum SendToProcessPages {
    SCAN_CONTAINER_PAGE = 'ScanContainerPage',
    CONFIRM_SEND_TO_PROCESS_PAGE = 'ConfirmSendToProcessPage'
}

interface processContainerState {
    pageToRender: SendToProcessPages
}

const initialState: processContainerState = {
    pageToRender: SendToProcessPages.SCAN_CONTAINER_PAGE
}

export const processContainer = createAsyncThunk<
    ProcessContainerOutput,
    ProcessContainerInput
>(
    'sendToProcess/processContainer',
    async (processContainerInput: ProcessContainerInput, { dispatch }) => {
        dispatch(resetUiAlertState());
        dispatch(setIsLoading());
        try {
            await RIVBFFService.triggerProcessContainerAPI(processContainerInput);
            dispatch(resetIsLoading());
            dispatch(setUiAlertState({
                alertLevel: AlertLevels.SUCCESS,
                alertTitleString: `${processContainerInput.containerId}`,
                alertMessageString: `${SuccessConstants.PROCESS_CONTAINER_SUCCESS}`
            }));

        } catch (error) {
            dispatch(resetIsLoading());
            if (error instanceof Error) {
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: error.message
                }));
            } else {
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: ErrorConstants.UNKNOWN_ERROR
                }));
            }
        }
    }
);

const processContainerSlice = createSlice({
    name: 'processContainer',
    initialState,
    reducers: {
        setPageToRender: (state, action) => {
            state.pageToRender = action.payload;
        },
        resetProcessContainerState: (state) => {
            state.pageToRender = initialState.pageToRender
        }
    }
});

export const { setPageToRender, resetProcessContainerState } = processContainerSlice.actions
export default processContainerSlice.reducer;