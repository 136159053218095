import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import chevronLeftSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-left-small';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import ContainerDetails from 'src/components/common/container-details/ContainerDetails';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { ScanIcons } from 'src/constants/general-media';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import { buildCloseContainerInput, CloseContainerInput } from 'src/models/closeContainer';
import { UIAlertState } from 'src/store/common-slices/uiSlice';
import { BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface CloseContainerPageProps {
    pageTitle: string;
}

interface CloseContainerStateProps {
    containerDetails?: ContainerDetailsModel;
    childContainers: ContainerDetailsModel[];
    uiAlertState: UIAlertState;
    containerClosedSuccessfully: boolean;
    warehouseId: string,
    associate: string
}

interface CloseContainerDispatchProps {
    setPageToRender: (pageToRender: BatchMoveContainersPages) => void;
    closeContainer: (closeContainerInput: CloseContainerInput) => void;
}

type Props = CloseContainerPageProps & CloseContainerStateProps & CloseContainerDispatchProps;

class CloseContainerPage extends React.Component<Props> {

    private onCloseContainerSubmit = async () => {
        const containerId = this.props.containerDetails?.containerId;
        if (containerId) {
            await this.props.closeContainer(buildCloseContainerInput(containerId, this.props.warehouseId, this.props.associate));
            if (this.props.containerClosedSuccessfully) {
                await this.props.setPageToRender(BatchMoveContainersPages.PRINT_LABEL);
            }
        }
    }

    public render() {
        return(
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="close-container-page">
                        {/*Back button to move back to child container scan page*/}
                        <Row>
                            <Button
                                type={"icon"}
                                onClick={() => this.props.setPageToRender(BatchMoveContainersPages.SCAN_CHILD_CONTAINERS)}
                                data-testid="back-button"
                            >
                                <Icon tokens={chevronLeftSmallTokens} />
                            </Button>
                            <Text type={props.titleSize}>{this.props.pageTitle}</Text>
                        </Row>

                        <ContainerDetails containerDetails={this.props.childContainers[0]}/>

                        <Column>
                            <img src={ScanIcons.SCAN_CLOSE_CONTAINER_ICON} alt={"Scan close container Icon"}
                                 className={"scan-icon-img"}/>
                            <Text type={props.titleSize} alignment="center">{AppStrings.CLOSE_CONTAINER}</Text>
                            <Button size={props.buttonSize} onClick={this.onCloseContainerSubmit} data-testid="close-container-button">
                                {AppStrings.CLOSE_CONTAINER_BUTTON}
                            </Button>
                        </Column>
                    </Column>
                )}
            </Responsive>
        );
    }
}

export default CloseContainerPage;