import {createAsyncThunk} from "@reduxjs/toolkit";
import {ReceiveContainerInput, ReceiveContainerOutput} from "src/models/receiveContainer";
import {resetIsLoading, resetUiAlertState, setIsLoading, setUiAlertState} from "src/store/common-slices/uiSlice";
import {AlertLevels} from "src/models/alert-model";
import * as ErrorConstants from "src/localization/string-ids/error-constants";
import {RECEIVE_CONTAINER_SUCCESS} from "src/localization/string-ids/success-constants";
import RIVBFFService from "src/services/riv-bff-service";
import {resetContainerState} from "src/store/common-slices/getContainerSlice";

export const receiveContainer = createAsyncThunk<
    ReceiveContainerOutput,
    ReceiveContainerInput>(
    'receive/receiveContainer',
    async (receiveContainerInput: ReceiveContainerInput, { dispatch }) => {
        dispatch(resetUiAlertState());
        dispatch(setIsLoading());
        try {
            await RIVBFFService.triggerReceiveContainerAPI(receiveContainerInput);
            dispatch(resetIsLoading());
            dispatch(setUiAlertState({
                alertLevel: AlertLevels.SUCCESS,
                alertMessageString: RECEIVE_CONTAINER_SUCCESS
            }));
            dispatch(resetContainerState());
        } catch (error) {
            dispatch(resetIsLoading());
            if (error instanceof Error)
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: error.message
                }));
            else
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: ErrorConstants.UNKNOWN_ERROR
                }));
        }
    }
);