import { RootState } from 'src/store/store';
import { connect } from 'react-redux';
import HomePage from './HomePage';


const mapStateToProps = (state: RootState) => ({
    accessibleTools: state.Init.initData.associateDetails.accessibleTools,

});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps) (HomePage);