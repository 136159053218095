/**
 * Returns if the object is empty - null, undefined, '', {}
 *
 * @param object
 */
export function isEmpty(object: object | string | undefined): boolean {
    return !object || Object.keys(object).length === 0;
}

/**
 *
 * @param object Returns false if the object is empty - null, undefined, ''m {}
 */
export function isNotEmpty(object: object | string | undefined): boolean {
    return !isEmpty(object);
}