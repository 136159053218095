import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import { CloseContainerInput } from 'src/models/closeContainer';
import { closeContainer } from 'src/store/common-slices/closeContainerSlice';
import CloseContainerPage from './CloseContainerPage';
import { BatchMoveContainersPages, setPageToRender } from 'src/store/common-slices/batchMoveContainersSlice';
import { fetchContainerDetails } from 'src/store/common-slices/getContainerSlice';
import { GetContainerDetailsInput } from 'src/models/GetContainerDetails';

const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    childContainers: state.BatchMoveContainers.childContainers,
    uiAlertState: state.UI.alertState,
    containerClosedSuccessfully: state.CloseContainer.containerClosedSuccessfully,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    closeContainer: (closeContainerInput: CloseContainerInput) => dispatch(closeContainer(closeContainerInput)),
    setPageToRender: (pageToRender: BatchMoveContainersPages) => dispatch(setPageToRender(pageToRender)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseContainerPage);