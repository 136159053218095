import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import ButtonGroup, {ButtonOption} from '@amzn/meridian/button-group';
import BarcodeScan from 'src/components/common/barcode-scan/BarcodeScan';
import { isEmpty, isNotEmpty } from 'src/utils/object-utils';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import MultiContainerDetails from 'src/components/common/multi-container-details/MultiContainerDetails';
import Button from '@amzn/meridian/button';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { buildGetContainerDetailsInput, GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import { UIAlertState } from 'src/store/common-slices/uiSlice';
import { AlertLevels } from 'src/models/alert-model';
import * as ErrorConstants from 'src/localization/string-ids/error-constants';
import { BatchMoveContainersMode, BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface MultiChildContainersScanPageProps {
    barcodeScanIcon: string;
    barcodeScanText: string;
    barcodeScanInputLabel: string;
    barcodeScanInputPlaceholder: string;
    barcodeScanButtonText: string;
    barcodeScanButtonIcon: React.ReactElement;
    submitChildContainersButtonText: string;
    submitChildContainersButtonIcon: React.ReactElement;
    warehouseId: string,
    associate: string,
    locale: string
}

interface MultiChildContainersScanStateProps {
    containerDetails?: ContainerDetailsModel;
    childContainers: ContainerDetailsModel[];
    batchMoveMode: BatchMoveContainersMode;
}

interface MultiChildContainersScanDispatchProps {
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => void;
    setBatchMoveMode: (batchMoveContainersMode: BatchMoveContainersMode) => void;
    updateChildContainers: (newContainer: ContainerDetailsModel) => void;
    setUiAlertState: (alertState: UIAlertState) => void;
    setPageToRender: (pageToRender: BatchMoveContainersPages) => void;
}

type Props = MultiChildContainersScanPageProps & MultiChildContainersScanStateProps & MultiChildContainersScanDispatchProps;

class MultiChildContainersScanPage extends React.Component<Props> {

    private isContainerIdPresent = (containerId: string, childContainers: ContainerDetailsModel[]) => {
        return childContainers.some(container => container.containerId === containerId);
    };

    private onSubmit = async (containerId: string) => {
        /*  We'll insert the containerDetails inside the child containers only if it's not already present.
         *  Otherwise we will show an alert.
         *  This it to prevent scanning duplicate containers */
        if (!this.isContainerIdPresent(containerId, this.props.childContainers)) {
            await this.props.fetchContainerDetails(buildGetContainerDetailsInput(containerId, 
                this.props.warehouseId, this.props.associate, this.props.locale));
            const newContainer = this.props.containerDetails;
            if (newContainer) {
                this.props.updateChildContainers(newContainer);
            }
        } else {
            this.props.setUiAlertState({
                alertLevel: AlertLevels.ERROR,
                alertTitleString: containerId,
                alertMessageString: ErrorConstants.CONTAINER_ALREADY_SCANNED_ERROR
            })
        }
    }

    public render() {
        return(
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="multi-child-containers-page">
                        <ButtonGroup size={props.buttonGroupSize} type="outline" value={this.props.batchMoveMode}>
                            <ButtonOption
                                value={BatchMoveContainersMode.SINGLE_MODE}
                                onClick={() => this.props.setBatchMoveMode(BatchMoveContainersMode.SINGLE_MODE)}
                                data-testid="single-mode-button"
                            >
                                {AppStrings.SINGLE_CONTAINER_MODE}
                            </ButtonOption>
                            <ButtonOption
                                value={BatchMoveContainersMode.MULTI_MODE}
                                onClick={() => this.props.setBatchMoveMode(BatchMoveContainersMode.MULTI_MODE)}
                                data-testid="multi-mode-button"
                            >
                                {AppStrings.MULTI_CONTAINERS_MODE}
                            </ButtonOption>
                        </ButtonGroup>

                        <BarcodeScan
                            shouldRenderImageOrIcon={isEmpty(this.props.childContainers)}
                            scanImageOrIcon={this.props.barcodeScanIcon}
                            scanImageAltText="Scan Handheld Icon"
                            shouldRenderText={true}
                            scanText={this.props.barcodeScanText}
                            inputLabel={this.props.barcodeScanInputLabel}
                            inputPlaceholder={this.props.barcodeScanInputPlaceholder}
                            shouldRenderButton={true}
                            buttonText={this.props.barcodeScanButtonText}
                            buttonIcon={this.props.barcodeScanButtonIcon}
                            onSubmit={this.onSubmit}
                        />

                        <MultiContainerDetails childContainers={this.props.childContainers}/>

                        {isNotEmpty(this.props.childContainers) &&
                            <Button size={props.buttonSize} onClick={() => this.props.setPageToRender(BatchMoveContainersPages.SCAN_DESTINATION_CONTAINER)}>
                                {this.props.submitChildContainersButtonText}
                                {this.props.submitChildContainersButtonIcon}
                            </Button>
                        }
                    </Column>
                )}
            </Responsive>
        );
    }
}

export default MultiChildContainersScanPage;
