import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BatchMoveContainersInput, BatchMoveContainersOutput, MoveContainerMode } from 'src/models/batchMoveContainers';
import { resetIsLoading, resetUiAlertState, setIsLoading, setUiAlertState } from 'src/store/common-slices/uiSlice';
import * as ErrorConstants from 'src/localization/string-ids/error-constants';
import * as SuccessConstants from 'src/localization/string-ids/success-constants';
import { AlertLevels } from 'src/models/alert-model';
import { resetContainerState } from 'src/store/common-slices/getContainerSlice';
import RIVBFFService from 'src/services/riv-bff-service';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';

export enum BatchMoveContainersPages {
    SCAN_CHILD_CONTAINERS = 'ScanChildContainers',
    SCAN_DESTINATION_CONTAINER = 'ScanDestinationContainer',
    CLOSE_CONTAINER = 'CloseContainer',
    PRINT_LABEL = 'PrintLabel'
}

export enum BatchMoveContainersMode {
    SINGLE_MODE = 'SingleMode',
    MULTI_MODE = 'MultiMode'
}

interface batchMoveContainersState {
    childContainers: ContainerDetailsModel[],
    destinationContainerId: string,
    batchMoveMode: BatchMoveContainersMode,
    pageToRender: BatchMoveContainersPages
}

const initialState: batchMoveContainersState = {
    childContainers: [],
    destinationContainerId: '',
    batchMoveMode: BatchMoveContainersMode.SINGLE_MODE,
    pageToRender: BatchMoveContainersPages.SCAN_CHILD_CONTAINERS
}

export const batchMoveContainers = createAsyncThunk<
    BatchMoveContainersOutput,
    BatchMoveContainersInput>(
    'moveContainers/batchMoveContainers',
    async (batchMoveContainersInput: BatchMoveContainersInput, { dispatch }) => {
        dispatch(resetUiAlertState());
        dispatch(setIsLoading());
        try {
            await RIVBFFService.triggerBatchMoveContainersAPI(batchMoveContainersInput);
            dispatch(resetIsLoading());
            dispatch(resetContainerState());
            dispatch(setUiAlertState({
                alertLevel: AlertLevels.SUCCESS,
                alertTitleString: `${batchMoveContainersInput.movingContainers.length} ${SuccessConstants.CONTAINERS}`,
                alertMessageString: batchMoveContainersInput.mode === MoveContainerMode.CONTAINERIZE ?
                    SuccessConstants.CONTAINERIZATION_SUCCESS : SuccessConstants.MOVE_SUCCESS,
            }));

        } catch (error) {
            dispatch(resetIsLoading());
            if (error instanceof Error)
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: error.message
                }));
            else
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: ErrorConstants.UNKNOWN_ERROR
                }));
        }
    }
);

const batchMoveContainersSlice = createSlice({
    name: 'batchMoveContainers',
    initialState,
    reducers: {
        setPageToRender: (state, action) => {
            state.pageToRender = action.payload;
        },
        setBatchMoveMode: (state, action) => {
            state.batchMoveMode = action.payload;
        },
        updateChildContainers: (state, action: PayloadAction<ContainerDetailsModel>) => {
            state.childContainers.push(action.payload);
        },
        updateDestinationContainerId: (state, action) => {
            state.destinationContainerId = action.payload;
        },
        resetChildAndDestinationContainers: (state) => {
            state.childContainers = initialState.childContainers;
            state.destinationContainerId = initialState.destinationContainerId;
        },
        resetBatchMoveContainersState: (state) => {
            state.childContainers = initialState.childContainers;
            state.destinationContainerId = initialState.destinationContainerId;
            state.batchMoveMode = initialState.batchMoveMode;
            state.pageToRender = initialState.pageToRender;
        }
    }
});

export const {
    setPageToRender,
    setBatchMoveMode,
    resetChildAndDestinationContainers,
    resetBatchMoveContainersState,
    updateChildContainers,
    updateDestinationContainerId
} = batchMoveContainersSlice.actions;
export default batchMoveContainersSlice.reducer;