import React from 'react';
import Column from '@amzn/meridian/column';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import * as AppRoutes from 'src/routing/constants/routes';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import SingleContainer
    from 'src/components/pages/containerization-page/single-containerization/SingleContainerizationContainer';
import MultiContainer
    from 'src/components/pages/containerization-page/multi-containerization/MultiContainerizationContainer';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { isNotEmpty } from 'src/utils/object-utils';
import './Containerization.scss';
import { BatchMoveContainersMode, BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface ContainerizationStateProps {
    containerDetails?: ContainerDetailsModel;
    batchMoveMode: BatchMoveContainersMode;
    childContainers: ContainerDetailsModel[];
    pageToRender: BatchMoveContainersPages;
}

interface ContainerizationDispatchProps {
    resetContainerState: () => void;
    resetBatchMoveContainersState: () => void;
}

type Props = ContainerizationStateProps & ContainerizationDispatchProps;

class Containerization extends React.Component<Props> {

    public render() {
        return(
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="containerization">
                        <BreadcrumbGroup>
                            <Breadcrumb href={AppRoutes.HOME_PAGE_ROUTE}>{AppStrings.HOME}</Breadcrumb>
                            <Breadcrumb href={AppRoutes.CONTAINERIZATION_PAGE_ROUTE}>{AppStrings.CONTAINERIZATION}</Breadcrumb>
                            {isNotEmpty(this.props.childContainers) &&
                                <Breadcrumb>
                                    {this.props.pageToRender === BatchMoveContainersPages.CLOSE_CONTAINER
                                        ? AppStrings.CLOSE_CONTAINER
                                        : (this.props.pageToRender === BatchMoveContainersPages.PRINT_LABEL ? AppStrings.PRINT_LABEL
                                            : (this.props.batchMoveMode === BatchMoveContainersMode.SINGLE_MODE ? AppStrings.SINGLE_CONTAINERIZATION_MODE : AppStrings.MULTI_CONTAINERIZATION_MODE)
                                          )
                                    }
                                </Breadcrumb>
                            }
                        </BreadcrumbGroup>

                        {this.props.batchMoveMode === BatchMoveContainersMode.SINGLE_MODE &&
                            <SingleContainer />
                        }

                        {this.props.batchMoveMode === BatchMoveContainersMode.MULTI_MODE &&
                            <MultiContainer />
                        }

                    </Column>
                )}
            </Responsive>
        )
    }

    componentWillUnmount() {
        this.props.resetContainerState();
        this.props.resetBatchMoveContainersState();
    }
}

export default Containerization;

