import { configureStore } from '@reduxjs/toolkit';
import REDUCERS from 'src/store/reducers';

const store = configureStore({
    reducer: REDUCERS
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;