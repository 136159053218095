import React from 'react';
import { Link } from 'react-router-dom';
import FooterItem from 'src/components/app/footer/footer-item/FooterItem';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import * as AppRoutes from 'src/routing/constants/routes';

import './Footer.scss'


interface FooterProps {
    accessibleTools: string[];
}

class Footer extends React.Component<FooterProps> {
    private CONTAINERIZATION = "Containerization";
    private MOVE = "Move";
    private IDENTIFICATION = "Identification";
    private SEND_TO_PROCESS = "Process";

    private shouldToolBeVisible = (toolName: string): boolean => {
        if(this.props.accessibleTools.includes(toolName)) {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div className="footer-container" data-testid="footer">
                {!this.shouldToolBeVisible(this.CONTAINERIZATION) ? null :
                    <Link to={AppRoutes.CONTAINERIZATION_PAGE_ROUTE}>
                        <FooterItem footerItemText={AppStrings.FOOTER_ITEM_CONTAINER} footerItemTokenIdentifier="container"/>
                    </Link>
                }
                {!this.shouldToolBeVisible(this.MOVE) ? null :
                    <Link to={AppRoutes.MOVE_PAGE_ROUTE}>
                        <FooterItem footerItemText={AppStrings.FOOTER_ITEM_MOVE} footerItemTokenIdentifier="move"/>
                    </Link>
                }
                {!this.shouldToolBeVisible(this.IDENTIFICATION) ? null :
                    <Link to={AppRoutes.IDENTIFICATION_PAGE_ROUTE}>
                        <FooterItem footerItemText={AppStrings.FOOTER_ITEM_IDENTIFICATION} footerItemTokenIdentifier="identification"/>
                    </Link>
                }
                {!this.shouldToolBeVisible(this.SEND_TO_PROCESS) ? null :
                    <Link to={AppRoutes.SEND_TO_PROCESS_PAGE_ROUTE}>
                        <FooterItem footerItemText={AppStrings.FOOTER_ITEM_SEND} footerItemTokenIdentifier="send"/>
                    </Link>
                }
            </div>

        )
    }
}

export default Footer;