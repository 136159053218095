import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetContainerDetailsInput, GetContainerDetailsOutput } from 'src/models/GetContainerDetails';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { resetUiAlertState, setUiAlertState, setIsLoading, resetIsLoading } from 'src/store/common-slices/uiSlice';
import { AlertLevels } from 'src/models/alert-model';
import * as ErrorConstants from 'src/localization/string-ids/error-constants';
import RIVBFFService from 'src/services/riv-bff-service';

interface getContainerState {
    containerDetails?: ContainerDetailsModel;
}

const initialState: getContainerState = {
    containerDetails: undefined,
}

export const fetchContainerDetails = createAsyncThunk<GetContainerDetailsOutput, GetContainerDetailsInput>(
    'getContainer/fetchContainerDetails',
    async (getContainerDetailsInput: GetContainerDetailsInput, { dispatch, rejectWithValue }) => {
        dispatch(resetUiAlertState());
        dispatch(setIsLoading());

        try {
            const getContainerDetailsOutput = await RIVBFFService.triggerGetContainerDetailsAPI(getContainerDetailsInput);
            dispatch(resetIsLoading());
            return getContainerDetailsOutput;

        } catch (error) {
            dispatch(resetIsLoading());

            if (error instanceof Error) {
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertTitleString: `${getContainerDetailsInput.containerId}`,
                    alertMessageString: error.message
                }));
                return rejectWithValue(error.message);
            }
            else {
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertTitleString: `${getContainerDetailsInput.containerId}`,
                    alertMessageString: ErrorConstants.UNKNOWN_ERROR
                }));
                return rejectWithValue(ErrorConstants.UNKNOWN_ERROR);
            }

        }
    }
);

const getContainerSlice = createSlice({
    name: 'getContainer',
    initialState,
    reducers: {
        resetContainerState: (state) => {
            state.containerDetails = initialState.containerDetails;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchContainerDetails.pending, (state) => {
                state.containerDetails = undefined;
            })
            .addCase(fetchContainerDetails.fulfilled, (state, action) => {
                state.containerDetails = action.payload.container;
            })
            .addCase(fetchContainerDetails.rejected, (state, action) => {
                state.containerDetails = undefined;
            })
    }
});

export const { resetContainerState } = getContainerSlice.actions;
export default getContainerSlice.reducer;
