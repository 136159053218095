import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import PrintLabelPage from 'src/components/pages/close-container-page/print-label-page/PrintLabelPage';
import { PrintContainerLabelDetailsInput } from 'src/models/PrintContainerLabelDetails';
import { printContainer } from 'src/store/common-slices/printContainerSlice';
import { BatchMoveContainersPages, setPageToRender } from 'src/store/common-slices/batchMoveContainersSlice';


const mapStateToProps = (state: RootState) => ({
    containerDetails: state.CloseContainer.containerDetails,
    uiAlertState: state.UI.alertState,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    printContainerLabel: (printContainerLabelDetailsInput: PrintContainerLabelDetailsInput) => dispatch(printContainer(printContainerLabelDetailsInput)),
    setPageToRender: (pageToRender: BatchMoveContainersPages) => dispatch(setPageToRender(pageToRender))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintLabelPage);