import React from 'react';
import Loader from '@amzn/meridian/loader';

interface UILoaderStateProps {
    isLoading: boolean;
}

class UILoader extends React.Component<UILoaderStateProps> {
    public render() {
        return(
            <div data-testid="uiloader">
                {this.props.isLoading &&
                    <Loader size="small" type="linear"/>
                }
            </div>

        );
    }
}

export default UILoader;