import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import ConfirmSendToProcessPage from './ConfirmSendToProcessPage';
import { processContainer, SendToProcessPages, setPageToRender } from 'src/store/common-slices/processContainerSlice';
import { ProcessContainerInput } from 'src/models/processContainer';

const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    uiAlertState: state.UI.alertState,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setPageToRender: (pageToRender: SendToProcessPages) => dispatch(setPageToRender(pageToRender)),
    processContainer: (processContainerInput: ProcessContainerInput) => dispatch(processContainer(processContainerInput))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSendToProcessPage);