import React from 'react';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import { LOGIN_URL } from './constants/urls';
import Link from '@amzn/meridian/link';
import Text from '@amzn/meridian/text';
import './PermissionsDeniedPage.scss';


const PermissionsDeniedPage: React.FC = () => {
  return (
     <div className='unauthorized'>
      <Text type="h100">{AppStrings.PERMISSION_DENIED}</Text>
      <Text>
        {AppStrings.USER_AUTHORIZATION_FAILED}
      </Text>
      <Text>
      {AppStrings.PLEASE_LOGIN_THROUGH}
      <Link
        href={LOGIN_URL}
      >
        {AppStrings.FCMENU}
      </Link>
      {AppStrings.SELECT_APPROPRIATE_TOOL}
      </Text>
    </div>
  );
};

export default PermissionsDeniedPage;
