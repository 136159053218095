import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ActionName, CloseContainerInput, CloseContainerOutput} from 'src/models/closeContainer';
import RIVBFFService from 'src/services/riv-bff-service';
import * as ErrorConstants from 'src/localization/string-ids/error-constants';
import {resetIsLoading, resetUiAlertState, setIsLoading, setUiAlertState} from 'src/store/common-slices/uiSlice';
import {AlertLevels} from 'src/models/alert-model';
import * as SuccessConstants from "src/localization/string-ids/success-constants";
import {ContainerDetailsModel} from "src/components/common/container-details/ContainerDetailsModel";

interface closeContainerState {
    containerClosedSuccessfully: boolean;
    containerDetails?: ContainerDetailsModel;
}

const initialState : closeContainerState = {
    containerClosedSuccessfully: false,
    containerDetails: undefined
}

export const closeContainer = createAsyncThunk<CloseContainerOutput, CloseContainerInput>(
    'containerization/closeContainer',
    async (closeContainerInput: CloseContainerInput, {dispatch, rejectWithValue}) => {
        dispatch(resetUiAlertState());
        dispatch(setIsLoading());

        try {
            const closeContainerOutput = await RIVBFFService.triggerCloseContainerAPI(closeContainerInput);
            dispatch(resetIsLoading());
            dispatch(setUiAlertState({
                alertLevel: AlertLevels.SUCCESS,
                alertTitleString: `${closeContainerInput.containerId}`,
                alertMessageString: SuccessConstants.CLOSE_CONTAINER_SUCCESS,
            }));
            return closeContainerOutput;

        } catch (error) {
            dispatch(resetIsLoading());
            if (error instanceof Error) {
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: error.message
                }));
                return rejectWithValue(error.message);
            } else {
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: ErrorConstants.UNKNOWN_ERROR
                }));
                return rejectWithValue(ErrorConstants.UNKNOWN_ERROR);
            }
        }
    }
);

const closeContainerSlice = createSlice({
    name: 'closeContainer',
    initialState,
    reducers: {
        resetCloseContainerFlag: (state) => {
            state.containerClosedSuccessfully = false;
        },
        resetContainerState: (state) => {
            state.containerDetails = initialState.containerDetails;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(closeContainer.pending, (state) => {
                state.containerClosedSuccessfully = false;
                state.containerDetails = undefined;
            })
            .addCase(closeContainer.fulfilled, (state, action) => {
                state.containerClosedSuccessfully = true;
                state.containerDetails = undefined;
                if (action.payload.action && (action.payload.action.actionName === ActionName.PRINT) &&
                    action.payload.action.actionPayload) {
                    state.containerDetails = action.payload.action.actionPayload.container;
                }
            })
            .addCase(closeContainer.rejected, (state, action) => {
                state.containerClosedSuccessfully = false;
                state.containerDetails = undefined;
            })
    }
});

export const { resetCloseContainerFlag, resetContainerState } = closeContainerSlice.actions;
export default closeContainerSlice.reducer;