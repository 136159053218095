import { RootState } from 'src/store/store';
import { connect } from 'react-redux';
import TopNav from './TopNav';


const mapStateToProps = (state: RootState) => ({
    accessibleTools: state.Init.initData.associateDetails.accessibleTools,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id

});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps) (TopNav);