import React from 'react';
import SearchField from '@amzn/meridian/search-field';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';
import Responsive from '@amzn/meridian/responsive';
import ContainerDetails from 'src/components/common/container-details/ContainerDetails';
import UILoader from 'src/components/common/loader/UILoaderContainer';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { SearchIcons } from 'src/constants/general-media';
import './Identification.scss';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import * as AppRoutes from 'src/routing/constants/routes';
import { responsiveProps } from 'src/constants/responsiveProps';
import { buildGetContainerDetailsInput, GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import StatusAlert from 'src/components/common/status-alert/StatusAlertContainer';


interface IdentificationProps {
    containerDetails?: ContainerDetailsModel;
    warehouseId: string;
    associate: string;
    locale: string;
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => void;
    resetContainerState: () => void;
}

interface IdentificationState {
    searchFieldValue: string;
    containerId: string;
}

class Identification extends React.Component<IdentificationProps, IdentificationState> {
    constructor(props: IdentificationProps) {
        super(props);
        this.state = {
            searchFieldValue: '',
            containerId: ''
        };
    }

    private setSearchValue = (value: string): void => {
        this.setState({
            searchFieldValue: value
        });
    }

    private onSearchSubmit = () => {
        const {searchFieldValue} = this.state;
        if (searchFieldValue) {
            this.setState({
                containerId: searchFieldValue,
                searchFieldValue: ''
            }, async () => {
                await this.props.fetchContainerDetails(buildGetContainerDetailsInput(searchFieldValue, 
                    this.props.warehouseId, this.props.associate, this.props.locale));
            });
        }
    };

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="identification">
                        <BreadcrumbGroup>
                            <Breadcrumb href={AppRoutes.HOME_PAGE_ROUTE}>{AppStrings.HOME}</Breadcrumb>
                            <Breadcrumb>{AppStrings.IDENTIFICATION}</Breadcrumb>
                        </BreadcrumbGroup>
                        {!this.props.containerDetails &&
                            <Column alignmentHorizontal="center">
                                <img src={SearchIcons.SEARCH_MAGNIFYING_GLASS} alt="Search Icon"
                                     className="identification-icon"/>
                                <Text type={props.titleSize}>{AppStrings.IDENTIFICATION_CENTER_TEXT}</Text>
                            </Column>
                        }
                        <SearchField
                            inaccessibleAutoFocus={true}
                            type="text"
                            value={this.state.searchFieldValue}
                            onChange={this.setSearchValue}
                            onSubmit={this.onSearchSubmit}
                            size="xlarge"
                            label={AppStrings.IDENTIFICATION_SEARCHFIELD_LABEL}
                            placeholder={AppStrings.IDENTIFICATION_SEARCHFIELD_PLACEHOLDER}
                            searchButton={true}
                        />
                        <UILoader/>
                        <StatusAlert/>

                        {this.props.containerDetails &&
                            <ContainerDetails containerDetails={this.props.containerDetails}/>
                        }
                    </Column>
                )}
            </Responsive>
        );
    }

    componentWillUnmount() {
        this.props.resetContainerState();
    }
}

export default Identification;