import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import UILoader from 'src/components/common/loader/UILoaderContainer';
import StatusAlert from 'src/components/common/status-alert/StatusAlertContainer';
import { BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';
import MultiChildContainersScanPage
    from 'src/components/pages/child-container-scan/multi-mode/MultiChildContainersScanPageContainer';
import DestinationContainerScanPage
    from 'src/components/pages/destination-container-scan/DestinationContainerScanPageContainer';
import { MoveIcons, ScanIcons } from 'src/constants/general-media';
import { MoveContainerMode } from 'src/models/batchMoveContainers';
import * as AppStrings from 'src/localization/string-ids/app-strings';

interface MultiMoveStateProps {
    pageToRender: BatchMoveContainersPages;
}

interface MultiMoveDispatchProps {
    resetChildAndDestinationContainers: () => void;
    resetContainerState: () => void;
}

type Props = MultiMoveStateProps & MultiMoveDispatchProps;

class MultiMove extends React.Component<Props, any> {
    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="multi-move">

                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_CHILD_CONTAINERS &&
                            <MultiChildContainersScanPage
                                barcodeScanIcon={ScanIcons.SCAN_HANDHELD_ICON}
                                barcodeScanText={AppStrings.MOVE_MAIN_TEXT}
                                barcodeScanInputLabel={AppStrings.MULTI_MOVE_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.MULTI_MOVE_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.MOVE_BUTTON}
                                barcodeScanButtonIcon={<img src={MoveIcons.MOVE_DEFAULT_ICON} alt="Move Icon"/>}
                                submitChildContainersButtonText={AppStrings.MOVE_BUTTON}
                                submitChildContainersButtonIcon={<img src={MoveIcons.MOVE_DEFAULT_ICON} alt="Move Icon"/>}
                            />
                        }

                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_DESTINATION_CONTAINER &&
                            <DestinationContainerScanPage
                                pageTitle={AppStrings.MOVE_DESTINATION_CONTAINER_MAIN_TEXT}
                                barcodeScanIcon={ScanIcons.SCAN_TRAILER_ICON}
                                barcodeScanText={AppStrings.MOVE_DESTINATION_SCAN_TEXT}
                                barcodeScanInputLabel={AppStrings.MOVE_DESTINATION_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.MOVE_DESTINATION_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.MOVE_BUTTON}
                                barcodeScanButtonIcon={<img src={MoveIcons.MOVE_DEFAULT_ICON} alt="Move Icon"/>}
                                moveContainerMode={MoveContainerMode.MOVE}
                            />
                        }

                        <UILoader />
                        <StatusAlert />
                    </Column>
                )}
            </Responsive>
        );
    }

    componentWillUnmount() {
        this.props.resetContainerState();
        this.props.resetChildAndDestinationContainers();
    }
}

export default MultiMove;