import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import * as AppRoutes from 'src/routing/constants/routes';
import './SendToProcess.scss';
import { SendToProcessPages } from 'src/store/common-slices/processContainerSlice';
import ScanContainerPage
    from 'src/components/pages/send-to-process-page/scan-container-page/ScanContainerPageContainer';
import ConfirmSendToProcessPage
    from 'src/components/pages/send-to-process-page/confirm-send-to-process-page/ConfirmSendToProcessPageContainer';
import UILoader from 'src/components/common/loader/UILoaderContainer';
import StatusAlert from 'src/components/common/status-alert/StatusAlertContainer';


interface SendToProcessStateProps {
    pageToRender: SendToProcessPages;
}

interface SendToProcessDispatchProps {
    resetProcessContainerState: () => void;
    resetContainerState: () => void;
}

type Props = SendToProcessStateProps & SendToProcessDispatchProps;

class SendToProcess extends React.Component<Props, any> {

    public render() {
        return(
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="send-to-process">
                        <BreadcrumbGroup>
                            <Breadcrumb href={AppRoutes.HOME_PAGE_ROUTE}>{AppStrings.HOME}</Breadcrumb>
                            <Breadcrumb href={AppRoutes.SEND_TO_PROCESS_PAGE_ROUTE}>{AppStrings.SEND}</Breadcrumb>
                        </BreadcrumbGroup>

                        {this.props.pageToRender === SendToProcessPages.SCAN_CONTAINER_PAGE &&
                            <ScanContainerPage />
                        }

                        {this.props.pageToRender === SendToProcessPages.CONFIRM_SEND_TO_PROCESS_PAGE &&
                            <ConfirmSendToProcessPage />
                        }

                        <UILoader />
                        <StatusAlert />

                    </Column>
                )}
            </Responsive>
        );
    }

    componentWillUnmount() {
        this.props.resetContainerState();
        this.props.resetProcessContainerState();
    }
}

export default SendToProcess;