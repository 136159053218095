import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import { ScanIcons } from 'src/constants/general-media';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import BarcodeScan from 'src/components/common/barcode-scan/BarcodeScan';
import { buildGetContainerDetailsInput, GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { SendToProcessPages } from 'src/store/common-slices/processContainerSlice';
import shareTokens from '@amzn/meridian-tokens/base/icon/share';
import Icon from '@amzn/meridian/icon';

interface ScanContainerPageStateProps {
    containerDetails?: ContainerDetailsModel,
    warehouseId: string,
    associate: string,
    locale: string
}

interface ScanContainerPageDispatchProps {
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => void;
    setPageToRender: (pageToRender: SendToProcessPages) => void;
}

type Props = ScanContainerPageStateProps & ScanContainerPageDispatchProps;

class ScanContainerPage extends React.Component<Props, any> {

    private onSubmit = async (containerId: string) => {
        await this.props.fetchContainerDetails(buildGetContainerDetailsInput(containerId, 
            this.props.warehouseId, this.props.associate, this.props.locale));
        if (this.props.containerDetails) {
            this.props.setPageToRender(SendToProcessPages.CONFIRM_SEND_TO_PROCESS_PAGE);
        }
    }

    public render() {
        return(
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column>
                        <BarcodeScan
                            shouldRenderImageOrIcon={true}
                            scanImageOrIcon={ScanIcons.SCAN_HANDHELD_ICON}
                            scanImageAltText="Scan Handheld Icon"
                            shouldRenderText={true}
                            scanText={AppStrings.SEND_TO_PROCESS_MAIN_TEXT}
                            inputLabel={AppStrings.SEND_TO_PROCESS_INPUT_LABEL}
                            inputPlaceholder={AppStrings.SEND_TO_PROCESS_INPUT_PLACEHOLDER}
                            shouldRenderButton={true}
                            buttonText={AppStrings.SEND_TO_PROCESS_BUTTON}
                            buttonIcon={<Icon tokens={shareTokens} />}
                            onSubmit={this.onSubmit}
                        />
                    </Column>
                )}
            </Responsive>
        );
    }
}

export default ScanContainerPage;