import {ContainerDetailsModel} from "src/components/common/container-details/ContainerDetailsModel";

export interface CloseContainerInput {
    containerId: string;
    warehouseId: string;
    associate: string;
    locale?: string;
}

export enum ActionName {
    PRINT = 'PRINT'
}

interface ActionPayload {
    container: ContainerDetailsModel;
}

interface Action {
    actionName: ActionName
    actionPayload?: ActionPayload
}

export interface CloseContainerOutput {
    action?: Action;
}

export const buildCloseContainerInput = (containerId: string, warehouseId: string, associate: string): CloseContainerInput => {
    return {
        containerId: containerId,
        warehouseId: warehouseId,
        associate: associate
    }
}