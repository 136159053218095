import getContainerReducer from 'src/store/common-slices/getContainerSlice';
import uiReducer from 'src/store/common-slices/uiSlice';
import closeContainerReducer from 'src/store/common-slices/closeContainerSlice';
import batchMoveContainersReducer from 'src/store/common-slices/batchMoveContainersSlice';
import processContainerReducer from 'src/store/common-slices/processContainerSlice';
import initReducer from 'src/store/common-slices/initSlice';

const REDUCERS = {
    GetContainer: getContainerReducer,
    UI: uiReducer,
    CloseContainer: closeContainerReducer,
    BatchMoveContainers: batchMoveContainersReducer,
    ProcessContainer: processContainerReducer,
    Init: initReducer
}

export default REDUCERS;