// Folder link - https://console.harmony.a2z.com/media-central/search-browse/browse/1/wwrr/riv-img-assets
export enum Icons {
    RIV_ICON_SMALL = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/RIV_Logo_Small.svg',
    RIV_ICON_MAIN = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/RIV_Logo_Main.svg',
    RIV_SEARCH_GROUP_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/search_group.svg'
}

export enum MoveIcons {
    MOVE_DEFAULT_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/move_default.svg',
    MOVE_SELECTED_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/move_selected.svg'
}

export enum PrintIcons {
    PRINTER_ICON = "https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/scan_print_label._CB564485668_.svg"
}

export enum SearchIcons {
    SEARCH_MAGNIFYING_GLASS = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/magnifying_glass.svg'
}

export enum ScanIcons {
    SCAN_HANDHELD_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/scan_handheld.svg',
    SCAN_PALETTE_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/scan_palette.svg',
    SCAN_CLOSE_CONTAINER_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/scan_close_container.svg',
    SCAN_TRAILER_ICON = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/scan_trailer.svg'
}

export enum Thumbnails {
    // TODO: Upload Item Thumbnail in the folder
    ITEM_THUMBNAIL = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/item_tumbnail.svg',
    TOTE_THUMBNAIL = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/tote_thumbnail.svg',
    PALLET_THUMBNAIL = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/palette_tumbnail.svg',

    // TODO: Upload Stacking Area, Trailer Thumbnail in the folder
    STACKING_AREA_THUMBNAIL = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/palette_tumbnail.svg',
    TRAILER_THUMBNAIL = 'https://m.media-amazon.com/images/G/01/wwrr/riv-img-assets/palette_tumbnail.svg'
}