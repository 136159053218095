import React from 'react';
import Alert from '@amzn/meridian/alert';
import { isEmpty } from "src/utils/object-utils";
import { AlertLevel, AlertLevels } from "src/models/alert-model";
import {
    DEFAULT_ALERT_LEVEL_TO_TIMEOUT_MAPPING
} from "src/components/common/status-alert/constants/alert-level-to-timeout-map";

interface StatusAlertStateProps {
    alertLevel: AlertLevel;
    alertTitleString: string;
    alertMessageString: string;
    alertTimeoutInMs?: number;
    alertSize?: string;
}

interface StatusAlertDispatchProps {
    resetUiAlertState: () => void;
}

type Props = StatusAlertStateProps & StatusAlertDispatchProps;

class StatusAlert extends React.Component<Props> {

    private timeOutVariable: any;
    private handleResetAlert = (delayInMs: number): void => {
        if (delayInMs > 0) {
            this.timeOutVariable = setTimeout(() => {
                this.props.resetUiAlertState();
            }, delayInMs);
        }
    }

    private getSize = (): any => {
        return isEmpty(this.props.alertSize) ? 'large' : this.props.alertSize;
    }

    public render() {
        // reset previous timeout
        clearTimeout(this.timeOutVariable);

        // set new timeout
        if (this.props.alertTimeoutInMs && this.props.alertTimeoutInMs >= 0) {
            this.handleResetAlert(this.props.alertTimeoutInMs)
        } else if (this.props.alertLevel !== AlertLevels.UNDEFINED) {
            this.handleResetAlert(DEFAULT_ALERT_LEVEL_TO_TIMEOUT_MAPPING[this.props.alertLevel]);
        }

        return (
            <div data-testid="status-alert">
                { this.props.alertLevel !== AlertLevels.UNDEFINED && (
                    <Alert
                        type={this.props.alertLevel}
                        title={this.props.alertTitleString}
                        size={this.getSize()}
                    >
                        {this.props.alertMessageString}
                    </Alert>
                )}
            </div>

        );
    }
}

export default StatusAlert;