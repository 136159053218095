import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import { MoveIcons, ScanIcons } from 'src/constants/general-media';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import UILoader from 'src/components/common/loader/UILoaderContainer';
import StatusAlert from 'src/components/common/status-alert/StatusAlertContainer';
import SingleChildContainersScanPage
    from 'src/components/pages/child-container-scan/single-mode/SingleChildContainerScanPageContainer';
import DestinationContainerScanPage
    from 'src/components/pages/destination-container-scan/DestinationContainerScanPageContainer';
import { MoveContainerMode } from 'src/models/batchMoveContainers';
import { BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface SingleMoveStateProps {
    pageToRender: BatchMoveContainersPages;
}

interface SingleMoveDispatchProps {
    resetContainerState: () => void;
    resetChildAndDestinationContainers: () => void;
}

type Props = SingleMoveStateProps & SingleMoveDispatchProps;

class SingleMove extends React.Component<Props> {

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="single-move">
                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_CHILD_CONTAINERS &&
                            <SingleChildContainersScanPage
                                barcodeScanIcon={ScanIcons.SCAN_HANDHELD_ICON}
                                barcodeScanText={AppStrings.MOVE_MAIN_TEXT}
                                barcodeScanInputLabel={AppStrings.MOVE_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.MOVE_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.MOVE_BUTTON}
                                barcodeScanButtonIcon={<img src={MoveIcons.MOVE_DEFAULT_ICON} alt="Move Icon"/>}
                            />
                        }

                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_DESTINATION_CONTAINER &&
                            <DestinationContainerScanPage
                                pageTitle={AppStrings.MOVE_DESTINATION_CONTAINER_MAIN_TEXT}
                                barcodeScanIcon={ScanIcons.SCAN_TRAILER_ICON}
                                barcodeScanText={AppStrings.MOVE_DESTINATION_SCAN_TEXT}
                                barcodeScanInputLabel={AppStrings.MOVE_DESTINATION_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.MOVE_DESTINATION_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.MOVE_BUTTON}
                                barcodeScanButtonIcon={<img src={MoveIcons.MOVE_DEFAULT_ICON} alt="Move Icon"/>}
                                moveContainerMode={MoveContainerMode.MOVE}
                            />
                        }

                        <UILoader />
                        <StatusAlert />

                    </Column>
                )}
            </Responsive>
        )
    }

    componentWillUnmount() {
        this.props.resetContainerState();
        this.props.resetChildAndDestinationContainers();
    }
}

export default SingleMove;

