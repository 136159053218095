import React, {PureComponent} from 'react';
import Table, {TableActionBar, TableCell, TableRow} from '@amzn/meridian/table';
import {ContainerDetailsModel, ContainerType} from 'src/components/common/container-details/ContainerDetailsModel';
import createContainerDetailsToStringIDMap, {
    ContainerDetailsToStringIDMap
} from 'src/data-map/containerDetailsToStringID';
import {Thumbnails} from 'src/constants/general-media';
import Text from '@amzn/meridian/text';
import * as AppStrings from 'src/localization/string-ids/app-strings';

import './ContainerDetails.scss';

interface ContainerDetailsProps {
    containerDetails: ContainerDetailsModel;
}

class ContainerDetails extends PureComponent<ContainerDetailsProps> {

    private getThumbnailFromContainerType = (containerType: ContainerType): string => {
        switch (containerType) {
            case ContainerType.ITEM:
                return Thumbnails.ITEM_THUMBNAIL;
            case ContainerType.TOTE:
                return Thumbnails.TOTE_THUMBNAIL;
            case ContainerType.PALLET:
                return Thumbnails.PALLET_THUMBNAIL;
            case ContainerType.STACKING_AREA:
                return Thumbnails.STACKING_AREA_THUMBNAIL;
            case ContainerType.TRAILER:
                return Thumbnails.TRAILER_THUMBNAIL;
            default:
                return '';
        }
    }

    private getTableRowsForList = (containerDetailsToStringIDMap:ContainerDetailsToStringIDMap, key:string, value?:string[]): React.ReactElement[] => {
        const rows: React.ReactElement[] = [];
        if (!value)
            return rows;

        rows.push(
            <TableRow key={key}>
                <TableCell rowSpan={value.length}>{containerDetailsToStringIDMap[key as keyof ContainerDetailsToStringIDMap]}</TableCell>
                <TableCell header={true}>{value[0]}</TableCell>
                <TableCell/>
            </TableRow>
        );
        for (let i = 1; i < value.length; i++) {
            rows.push(
                <TableRow key={`${key}-${i}`}>
                    <TableCell header={true}>{value[i]}</TableCell>
                    <TableCell/>
                </TableRow>
            );
        }

        return rows;
    }

    private getTableRowsForObject = (containerDetailsToStringIDMap:ContainerDetailsToStringIDMap, key:string, value?:object): React.ReactElement[] => {
        const rows: React.ReactElement[] = [];
        if (!value)
            return rows;

        const nestedContainer = value as { [key: string]: string };
        const nestedKeys = Object.keys(nestedContainer);
        if (nestedKeys.length > 0) {

            rows.push(
                <TableRow key={key}>
                    <TableCell>{containerDetailsToStringIDMap[`${key}_${nestedKeys[0]}` as keyof ContainerDetailsToStringIDMap] || (nestedKeys[0]+":")}</TableCell>
                    <TableCell header={true}>{nestedContainer[nestedKeys[0]]}</TableCell>
                </TableRow>
            );

            for (let i = 1; i < nestedKeys.length; i++) {
                rows.push(
                    <TableRow key={`${key}-${i}`}>
                        <TableCell>{containerDetailsToStringIDMap[`${key}_${nestedKeys[i]}` as keyof ContainerDetailsToStringIDMap] || (nestedKeys[i]+":")}</TableCell>
                        <TableCell header={true}>{nestedContainer[nestedKeys[i]]}</TableCell>
                    </TableRow>
                );
            }
        }

        return rows;
    }

    public render() {
        const {containerDetails} = this.props;
        const containerDetailsToStringIDMap: ContainerDetailsToStringIDMap = createContainerDetailsToStringIDMap(containerDetails.containerType as ContainerType);
        return (
            <Table spacing="small" nestedRows={true} className="table-view" >
                <TableActionBar>
                    <Text type="b300">{AppStrings.CONTAINER_DETAILS_ACTION_BAR_RESULT}</Text>
                    <Text type="h200">{containerDetails.containerId as string}</Text>
                </TableActionBar>

                <TableRow>
                    <TableCell>
                        <div className="container-div">
                            <img src={this.getThumbnailFromContainerType(containerDetails.containerType as ContainerType)}
                                 alt={'container thumbnail'} className="container-thumbnail"/>
                            <Text className="container-id">{containerDetailsToStringIDMap["containerId"]}</Text>
                        </div>
                    </TableCell>
                    <TableCell header={true}>{containerDetails["containerId"]}</TableCell>
                    <TableCell />
                </TableRow>

                <TableRow>
                    <TableCell>{containerDetailsToStringIDMap["warehouseId"]}</TableCell>
                    <TableCell header={true}>{containerDetails["warehouseId"]}</TableCell>
                    <TableCell />
                </TableRow>

                {this.getTableRowsForList(containerDetailsToStringIDMap ,"sortationCategories", containerDetails["sortationCategories"])}

                {containerDetails.parentContainer && <TableRow>
                    <TableCell>{containerDetailsToStringIDMap["parentContainer"]}</TableCell>
                    <TableCell header={true}>
                        {containerDetails["parentContainer"]?.containerType}
                        ({containerDetails["parentContainer"]?.containerId})
                    </TableCell>
                    <TableCell/>
                </TableRow>}

                <TableRow>
                    <TableCell>{containerDetailsToStringIDMap["latestProcessingDate"]}</TableCell>
                    <TableCell header={true}>{containerDetails["latestProcessingDate"]}</TableCell>
                    <TableCell />
                </TableRow>

                <TableRow>
                    <TableCell>{containerDetailsToStringIDMap["numOfChildContainers"]}</TableCell>
                    <TableCell header={true}>{containerDetails["numOfChildContainers"]}</TableCell>
                    <TableCell />
                </TableRow>

                {this.getTableRowsForObject(containerDetailsToStringIDMap ,"containerProperties", containerDetails["containerProperties"])}

                {this.getTableRowsForObject(containerDetailsToStringIDMap, "latestContainerOperation", containerDetails["latestContainerOperation"])}

            </Table>
        )
    }
}

export default ContainerDetails;
