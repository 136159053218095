export const AUTHENTICATION_FAILED_MESSAGE = "Please login through FCMenu";
export const PERMISSIONS_DENIED_MESSAGE = "User does not have permissions to access any tool in RIV";

// ******* User unauthenticated Strings *******
export const FCMENU = "FCMenu";
export const PLEASE_LOGIN_THROUGH  = "Please login through ";
export const SELECT_APPROPRIATE_TOOL  = " to select the appropriate tool";
export const USER_AUTHENTICATION_FAILED = "User authentication failed.";
export const USER_AUTHORIZATION_FAILED = "User authorization failed.";


// ******* Permission Denied Strings *******
export const PERMISSION_DENIED = 'You do not have permissions to access any tool in RIV';

// ******* TopNav Strings *******

export const TOP_NAV_BUTTON_PRINT = 'Print';

// ******* HomePage Strings *******

export const HOMEPAGE_CENTER_TEXT = 'Search for LPN, Totes, Pallets, etc.'
export const HOMEPAGE_SEARCHFIELD_LABEL = 'Search for...';
export const HOMEPAGE_SEARCHFIELD_PLACEHOLDER = 'LPN, ts, pa, SC, OB...';

//  ******* Footer Strings *******

export const FOOTER_ITEM_CONTAINER = 'Containerize';
export const FOOTER_ITEM_MOVE = 'Move';
export const FOOTER_ITEM_IDENTIFICATION = 'Identification';
export const FOOTER_ITEM_SEND = 'Send';

//  ******* Identification Strings *******

export const IDENTIFICATION_CENTER_TEXT = 'Scan LPN or Tote';
export const IDENTIFICATION_SEARCHFIELD_LABEL = 'Enter LPN or Tote';
export const IDENTIFICATION_SEARCHFIELD_PLACEHOLDER = 'tsXxxx';

//  ******* Receive Strings *******

export const RECEIVE_CENTER_TEXT = 'Scan VRID or Dock Door';
export const RECEIVE_SEARCHFIELD_LABEL = 'Enter VRID or Dock Door';
export const RECEIVE_SEARCHFIELD_PLACEHOLDER = 'tsXxxx';

// ******* Container Details Strings *******

export const CONTAINER_DETAILS_ACTION_BAR_RESULT = 'Result:';
export const CONTAINER_DETAILS_CLEAN_DECANT_DATE = 'CDD:';
export const CONTAINER_DETAILS_LPN_LABEL = 'LPN Label:';
export const CONTAINER_DETAILS_PALLET_ID = 'Pallet ID:';
export const CONTAINER_DETAILS_TOTE_ID = 'Tote ID:';
export const CONTAINER_DETAILS_STACKING_AREA_ID = 'Stacking Area ID:';
export const CONTAINER_DETAILS_DROP_ZONE_ID = 'Drop Zone ID:';
export const CONTAINER_DETAILS_TRAILER_ID = 'Trailer ID:';
export const CONTAINER_DETAILS_ITEMS = 'Items:';
export const CONTAINER_DETAILS_TOTES = 'Totes:';
export const CONTAINER_DETAILS_DEFAULT_CONTAINER_ID = 'Container ID:';
export const CONTAINER_DETAILS_WAREHOUSE_ID = 'Warehouse ID:';
export const CONTAINER_DETAILS_SORTATION_CATEGORIES = 'Category(s):';
export const CONTAINER_DETAILS_PARENT_CONTAINER = 'Parent Container:';
export const CONTAINER_DETAILS_LATEST_PROCESSING_DATE = 'LPD:';
export const CONTAINER_DETAILS_CONTAINER_PROPERTIES = 'Properties:';
export const CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS = 'Operations:';
export const CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS_NAME = 'Latest Operation Name:';
export const CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS_TIME = 'Latest Operation Time:';
export const CONTAINER_DETAILS_LATEST_CONTAINER_OPERATIONS_ASSOCIATE = 'Latest Operation Associate:';

// ******* Multi Container Details Strings *******

export const MULTI_CONTAINER_DETAILS_SCANNED_CONTAINERS = 'Scanned Containers';
export const MULTI_CONTAINER_DETAILS_HEADER_QUANTITY = 'Quantity';
export const MULTI_CONTAINER_DETAILS_HEADER_CATEGORY = 'Category';
export const MULTI_CONTAINER_DETAILS_HEADER_CHILD_CONTAINERS = 'Child Containers';


// ******* Containerization Strings *******

export const CONTAINERIZATION_MAIN_TEXT = 'Scan Tote or Pallet'
export const CONTAINERIZATION_INPUT_LABEL = 'Scan Tote or Pallet. Scan Stacking Area to close it.';
export const MULTI_CONTAINERIZATION_INPUT_LABEL = 'Scan Tote or Pallet';
export const CONTAINERIZATION_INPUT_PLACEHOLDER = 'tsX088088, Paxxx or SC-xxxx';
export const MULTI_CONTAINERIZATION_INPUT_PLACEHOLDER = 'tsX088088 or Paxxx';
export const CONTAINERIZATION_BUTTON = 'Containerize';
export const CHILD_CONTAINER_SUBMIT_BUTTON = 'Containerize the scanned containers';
export const DESTINATION_SCAN_TEXT = 'Scan Pallet or Stacking Area';
export const DESTINATION_SCAN_INPUT_LABEL = 'Enter Pallet or Stacking Area';
export const DESTINATION_SCAN_INPUT_PLACEHOLDER = 'Paxxxx or SC-xxxx';
export const CLOSE_CONTAINER_BUTTON = 'Close Container';
export const DESTINATION_CONTAINER_MAIN_TEXT = 'Destination Container';

// ******* ButtonGroup Strings *******
export const SINGLE_CONTAINER_MODE = 'Single Container';
export const MULTI_CONTAINERS_MODE = 'Multiple Containers';

// ******* Breadcrumb Strings *******

export const HOME = 'Home';
export const IDENTIFICATION = 'Identification';
export const CONTAINERIZATION = 'Containerization';
export const SINGLE_CONTAINERIZATION_MODE = 'Single Mode';
export const MULTI_CONTAINERIZATION_MODE = 'Multi Mode';
export const CLOSE_CONTAINER = 'Close Container';
export const PRINT_LABEL = 'Print Label';
export const RECEIVE = 'Receive';
export const MOVE = 'Move';
export const MOVE_SINGLE_MODE = 'Single Mode';
export const MOVE_MULTI_MODE = 'Multi Mode';
export const PRINT = 'Print';
export const SEND = 'Send';

//  ******* Print Strings *******

export const PRINT_CENTER_TEXT = 'Scan Tote, Pallet or Stacking Area';
export const PRINT_SEARCHFIELD_LABEL = 'Enter Tote, Pallet or Stacking Area';
export const PRINT_SEARCHFIELD_PLACEHOLDER = 'tsXxxx';
export const PRINTER_SCAN_TEXT = 'Scan Printer IP';
export const PRINTER_SEARCHFIELD_LABEL = 'Enter Printer IP';
export const PRINTER_SEARCHFIELD_PLACEHOLDER = 'Printer IP';

// ******* Move Strings *******

export const MOVE_MAIN_TEXT = 'Scan LPN or Container';
export const MOVE_INPUT_LABEL = 'Scan Item, Tote or Pallet';
export const MULTI_MOVE_INPUT_LABEL = 'Scan Item, Tote or Pallet';
export const MOVE_INPUT_PLACEHOLDER = 'LPNxxx, tsX088088, Paxxx or SC-xxxx';
export const MULTI_MOVE_INPUT_PLACEHOLDER = 'LPNxxx, tsX088088, Paxxx or SC-xxxx';
export const MOVE_BUTTON = 'Move';
export const MOVE_DESTINATION_SCAN_TEXT = 'Scan Stacking Area or Trailer';
export const MOVE_DESTINATION_INPUT_LABEL = 'Enter Stacking Area or Trailer';
export const MOVE_DESTINATION_INPUT_PLACEHOLDER = 'SC-xxxx or Trailerxxx';
export const MOVE_DESTINATION_CONTAINER_MAIN_TEXT = 'Destination Container';

// ******* Send to Process Strings *******

export const SEND_TO_PROCESS_MAIN_TEXT = 'Scan container for processing';
export const SEND_TO_PROCESS_INPUT_LABEL = 'Enter container';
export const SEND_TO_PROCESS_INPUT_PLACEHOLDER = 'Paxxx or tsxxx';
export const SEND_TO_PROCESS_BUTTON = 'Send';