import React from 'react';
import Button from "@amzn/meridian/button";
import Column from '@amzn/meridian/column';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Icon from '@amzn/meridian/icon';
import mergeTokens from '@amzn/meridian-tokens/base/icon/merge';
import Responsive from '@amzn/meridian/responsive';
import Text from '@amzn/meridian/text';
import { responsiveProps } from 'src/constants/responsiveProps';
import * as AppRoutes from 'src/routing/constants/routes';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import './Receive.scss';
import {ScanIcons} from "src/constants/general-media";
import UILoader from "src/components/common/loader/UILoaderContainer";
import StatusAlert from "src/components/common/status-alert/StatusAlertContainer";
import BarcodeScan from "src/components/common/barcode-scan/BarcodeScan";
import {buildReceiveContainerInput, ReceiveContainerInput} from "src/models/receiveContainer";
import {ContainerDetailsModel} from "src/components/common/container-details/ContainerDetailsModel";
import {buildGetContainerDetailsInput, GetContainerDetailsInput} from "src/models/GetContainerDetails";
import ContainerDetails from "src/components/common/container-details/ContainerDetails";
import {isBlankString} from "src/utils/string-utils";

export interface ReceiveProps {
    containerDetails?: ContainerDetailsModel;
    warehouseId: string,
    associate: string,
    locale: string,
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => void;
    receiveContainer: (receiveContainerInput: ReceiveContainerInput) => void;
    resetContainerState: () => void;
}

export interface ReceiveState {
    containerId: string;
}

class Receive extends React.Component<ReceiveProps, ReceiveState> {
    constructor(props: ReceiveProps) {
        super(props);
        this.state = {
            containerId: ''
        };
    }

    private onReceiveSubmit = (containerId: string) => {
        this.setState({
            containerId: containerId
        }, async () => {
            if (this.state.containerId) {
                await this.props.fetchContainerDetails(buildGetContainerDetailsInput(containerId, 
                    this.props.warehouseId, this.props.associate, this.props.locale))
            }
        })
    };

    private onClickReceiveButton = () => {
        this.props.receiveContainer(buildReceiveContainerInput(this.state.containerId, this.props.warehouseId, this.props.associate))
    }

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="receive">
                            <BreadcrumbGroup>
                                <Breadcrumb href={AppRoutes.HOME_PAGE_ROUTE}>{AppStrings.HOME}</Breadcrumb>
                                <Breadcrumb href={AppRoutes.RECEIVE_PAGE_ROUTE}>{AppStrings.RECEIVE}</Breadcrumb>
                            </BreadcrumbGroup>

                        {!this.props.containerDetails &&
                            <Column alignmentHorizontal="center">
                                <img src={ScanIcons.SCAN_TRAILER_ICON} alt="Scan Icon"
                                     className="receive-icon"/>
                                <Text type={props.titleSize}>{AppStrings.RECEIVE_CENTER_TEXT}</Text>
                            </Column>
                            &&
                            <BarcodeScan
                                shouldRenderImageOrIcon={true}
                                scanImageOrIcon={ScanIcons.SCAN_HANDHELD_ICON}
                                scanImageAltText="Scan Handheld Icon"
                                shouldRenderText={true}
                                scanText={AppStrings.RECEIVE_CENTER_TEXT}
                                inputLabel={AppStrings.RECEIVE_SEARCHFIELD_LABEL}
                                inputPlaceholder={AppStrings.RECEIVE_SEARCHFIELD_PLACEHOLDER}
                                shouldRenderButton={true}
                                buttonText={AppStrings.RECEIVE}
                                buttonIcon={<Icon tokens={mergeTokens}/>}
                                onSubmit={this.onReceiveSubmit}
                            />
                        }
                        <UILoader/>
                        <StatusAlert/>

                        {this.props.containerDetails &&
                            <ContainerDetails containerDetails={this.props.containerDetails}/>
                        }
                        {this.props.containerDetails &&
                            <Button size={props.size} disabled={isBlankString(this.state.containerId)} onClick={this.onClickReceiveButton}>
                                {AppStrings.RECEIVE}
                                {<Icon tokens={mergeTokens}/>}
                            </Button>
                        }

                    </Column>
                )}
            </Responsive>
        )
    }
    componentWillUnmount() {
        this.props.resetContainerState();
    }
}

export default Receive;