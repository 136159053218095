import React from 'react';
import { Routes, Route } from 'react-router-dom';
import * as AppRoutes from 'src/routing/constants/routes';
import HomePage from 'src/components/pages/home-page/HomePageContainer';
import Identification from 'src/components/pages/identification-page/IdentificationContainer';
import Containerization from 'src/components/pages/containerization-page/ContainerizationContainer';
import Print from "src/components/pages/print-page/PrintContainer";
import Receive from "src/components/pages/receive-page/ReceiveContainer";
import Move from 'src/components/pages/move-page/MoveContainer';
import SendToProcess from 'src/components/pages/send-to-process-page/SendToProcessContainer';


class AppRouter extends React.Component{
    public render() {
        return(
            <Routes>
                <Route path={AppRoutes.HOME_PAGE_ROUTE} element={<HomePage/>} />
                <Route path={AppRoutes.IDENTIFICATION_PAGE_ROUTE} element={<Identification/>} />
                <Route path={AppRoutes.CONTAINERIZATION_PAGE_ROUTE} element={<Containerization/>} />
                <Route path={AppRoutes.PRINT_PAGE_ROUTE} element={<Print/>} />
                <Route path={AppRoutes.RECEIVE_PAGE_ROUTE} element={<Receive/>} />
                <Route path={AppRoutes.MOVE_PAGE_ROUTE} element={<Move/>} />
                <Route path={AppRoutes.SEND_TO_PROCESS_PAGE_ROUTE} element={<SendToProcess/>} />
            </Routes>
        )
    }
}

export default AppRouter;