import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import Move from 'src/components/pages/move-page/Move';
import { resetContainerState } from 'src/store/common-slices/getContainerSlice';
import { resetBatchMoveContainersState } from 'src/store/common-slices/batchMoveContainersSlice';

const mapStateToProps = (state: RootState) => ({
    batchMoveMode: state.BatchMoveContainers.batchMoveMode,
    childContainers: state.BatchMoveContainers.childContainers
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetContainerState: () => dispatch(resetContainerState()),
    resetBatchMoveContainersState: () => dispatch(resetBatchMoveContainersState()),
})

export default connect(mapStateToProps, mapDispatchToProps) (Move);