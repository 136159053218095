import React from 'react';
import { Link } from 'react-router-dom';
import Masthead, { MastheadTitle, MastheadMenuButton} from '@amzn/meridian/masthead';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import printTokens from '@amzn/meridian-tokens/base/icon/print';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import * as AppRoutes from 'src/routing/constants/routes';
import {Icons} from 'src/constants/general-media';

import './TopNav.scss';

interface TopNavProps {
    accessibleTools: string[];
    warehouseId: string;
    associate: string;
}

class TopNav extends React.Component<TopNavProps> {
    private PRINT = "Print";

    private shouldToolBeVisible = (toolName: string): boolean => {
        if(this.props.accessibleTools.includes(toolName)) {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div data-testid="top-nav" >
                <Masthead size="small">
                    <MastheadMenuButton/>
                    <MastheadTitle>
                        <img
                            src={Icons.RIV_ICON_SMALL}
                            alt="RIV logo"
                        />
                    </MastheadTitle>
                    <Tag>
                        {this.props.warehouseId}
                    </Tag>
                    <img
                        className="user-image-icon"
                        src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + this.props.associate}
                        alt={this.props.associate}
                    />
                    {!this.shouldToolBeVisible(this.PRINT) ? null :
                        <Link to={AppRoutes.PRINT_PAGE_ROUTE}>
                            <div className="top-nav-buttons">
                                <Text className="top-nav-buttons-text">{AppStrings.TOP_NAV_BUTTON_PRINT}</Text>
                                <Icon tokens={printTokens} color="primary"/>
                            </div>
                        </Link>
                    }
                </Masthead>
            </div>
        )
    }
}
export default TopNav;