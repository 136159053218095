import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { isNotEmpty } from 'src/utils/object-utils';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import * as AppRoutes from 'src/routing/constants/routes';
import SingleMove from 'src/components/pages/move-page/single-move/SingleMoveContainer';
import './Move.scss';
import { BatchMoveContainersMode } from 'src/store/common-slices/batchMoveContainersSlice';
import MultiMove from 'src/components/pages/move-page/multi-move/MultiMoveContainer';

interface MoveStateProps {
    batchMoveMode: BatchMoveContainersMode;
    childContainers: ContainerDetailsModel[];
}

interface MoveDispatchProps {
    resetContainerState: () => void;
    resetBatchMoveContainersState: () => void;
}

type Props = MoveStateProps & MoveDispatchProps;

class Move extends React.Component<Props> {

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="move">
                        <BreadcrumbGroup>
                            <Breadcrumb href={AppRoutes.HOME_PAGE_ROUTE}>{AppStrings.HOME}</Breadcrumb>
                            <Breadcrumb href={AppRoutes.MOVE_PAGE_ROUTE}>{AppStrings.MOVE}</Breadcrumb>
                            {isNotEmpty(this.props.childContainers) &&
                                <Breadcrumb>
                                    {this.props.batchMoveMode === BatchMoveContainersMode.SINGLE_MODE ? AppStrings.MOVE_SINGLE_MODE : AppStrings.MOVE_MULTI_MODE}
                                </Breadcrumb>
                            }
                        </BreadcrumbGroup>

                        {this.props.batchMoveMode === BatchMoveContainersMode.SINGLE_MODE &&
                            <SingleMove />
                        }

                        {this.props.batchMoveMode === BatchMoveContainersMode.MULTI_MODE &&
                            <MultiMove />
                        }

                    </Column>
                )}
            </Responsive>
        );
    }

    componentWillUnmount() {
        this.props.resetContainerState();
        this.props.resetBatchMoveContainersState();
    }
}

export default Move;