import { createSlice } from '@reduxjs/toolkit';
import { AlertLevel, AlertLevels } from 'src/models/alert-model';

export interface UIAlertState {
    alertLevel: AlertLevel;
    alertTitleString: string;
    alertMessageString: string;
    alertTimeoutInMs?: number;
    alertSize?: string;
}

interface UIState {
    alertState: UIAlertState;
    isLoading: boolean;
}

const initialState: UIState = {
    alertState: {
        alertLevel: AlertLevels.UNDEFINED,
        alertTitleString: '',
        alertMessageString: '',
    },
    isLoading: false
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setUiAlertState: (state, action) => {
            state.alertState = action.payload;
        },
        resetUiAlertState: (state) => {
            state.alertState = initialState.alertState;
        },
        setIsLoading: (state) => {
            state.isLoading = true;
        },
        resetIsLoading: (state) => {
            state.isLoading = initialState.isLoading;
        }
    }
});

export const { setUiAlertState, resetUiAlertState, setIsLoading, resetIsLoading } = uiSlice.actions;
export default uiSlice.reducer;

