import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as ErrorConstants from 'src/localization/string-ids/error-constants';
import { InitRequest, InitResponse } from 'src/models/init-model';
import RIVBFFService from 'src/services/riv-bff-service';
 
const FETCH_INIT_DATA = 'data/fetchInitData';
export interface InitState {
    initData: InitResponse;
    error: string | null;
  }
  
  const initialState: InitState = {
    initData: {
        associateDetails: {
            id: '',
            locales: [],
            accessibleTools: []
        },
        gradingNodeDetails: {
            id: ''
        }
    },
    error: null,
  };
  
  /*TODO: add unit tests for this slice. (currenlty no unit tests added for any slice)*/
  export const fetchInitResponse = createAsyncThunk<InitResponse, InitRequest, { rejectValue: string }>(
    FETCH_INIT_DATA, 
    async (initRequest: InitRequest, { rejectWithValue }) => {
    try {
        const initResponse = await RIVBFFService.triggerInitAPI(initRequest);
        return initResponse;
    } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            else {
                return rejectWithValue(typeof error === 'string' ? error : ErrorConstants.UNKNOWN_ERROR);
            }
    }
  });
  
  const initSlice = createSlice({
    name: 'init',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchInitResponse.pending, (state) => {
          state.error = null;
        })
        .addCase(fetchInitResponse.fulfilled, (state, action) => {
          state.initData = action.payload;
          state.error = null;
        })
        .addCase(fetchInitResponse.rejected, (state, action) => {
          state.error = action.payload ?? ErrorConstants.INIT_ERROR; ;
        });
    },
  });
  
  export default initSlice.reducer;