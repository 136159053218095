import React from 'react';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import { PrintIcons } from 'src/constants/general-media';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import Icon from '@amzn/meridian/icon';
import printTokens from '@amzn/meridian-tokens/base/icon/print';
import BarcodeScan from 'src/components/common/barcode-scan/BarcodeScan';
import ContainerLabelDetails from 'src/components/pages/print-page/ContainerLabelDetails';
import {
    buildPrintContainerLabelDetailsInput,
    PrintContainerLabelDetailsInput,
    PrintContainerLabelDetailsOutput
} from 'src/models/PrintContainerLabelDetails';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { UIAlertState } from 'src/store/common-slices/uiSlice';
import { AlertLevels } from 'src/models/alert-model';
import { BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface PrintLabelPageStateProps {
    containerDetails?: ContainerDetailsModel;
    uiAlertState: UIAlertState;
    warehouseId: string,
    associate: string
}

interface PrintLabelPageDispatchProps {
    printContainerLabel: (printContainerLabelDetailsInput: PrintContainerLabelDetailsInput) => PrintContainerLabelDetailsOutput;
    setPageToRender: (pageToRender: BatchMoveContainersPages) => void;
}

type Props = PrintLabelPageStateProps & PrintLabelPageDispatchProps;

class PrintLabelPage extends React.Component<Props, any> {

    private onPrintSubmit = async (printerId: string) => {
        if (printerId && this.props.containerDetails) {
            await this.props.printContainerLabel(buildPrintContainerLabelDetailsInput(this.props.containerDetails.containerId, printerId,
                this.props.warehouseId, this.props.associate
            ));
        }
        // If successfully completed print label call, move back to initial ChildContainers scan page
        if (this.props.uiAlertState.alertLevel === AlertLevels.SUCCESS) {
            this.props.setPageToRender(BatchMoveContainersPages.SCAN_CHILD_CONTAINERS)
        }
    }

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="print-label-page">
                        <BarcodeScan
                            shouldRenderImageOrIcon={true}
                            scanImageOrIcon={PrintIcons.PRINTER_ICON}
                            scanImageAltText={"Scan Printer Icon"}
                            shouldRenderText={true}
                            scanText={AppStrings.PRINTER_SCAN_TEXT}
                            inputLabel={AppStrings.PRINTER_SEARCHFIELD_LABEL}
                            inputPlaceholder={AppStrings.PRINTER_SEARCHFIELD_PLACEHOLDER}
                            shouldRenderButton={true}
                            buttonText={AppStrings.PRINT}
                            buttonIcon={<Icon tokens={printTokens}/>}
                            onSubmit={this.onPrintSubmit}
                        />
                        {this.props.containerDetails && <ContainerLabelDetails containerLabelDetails={this.props.containerDetails}/>}
                    </Column>
                )}
            </Responsive>
        );
    }
}

export default PrintLabelPage;