import { AppDispatch, RootState } from 'src/store/store';
import { fetchContainerDetails, resetContainerState } from 'src/store/common-slices/getContainerSlice';
import { connect } from 'react-redux';
import Identification from './Identification';
import { GetContainerDetailsInput } from 'src/models/GetContainerDetails';


const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id,
    locale: state.Init.initData.associateDetails.locales[0]

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => dispatch(fetchContainerDetails(getContainerDetailsInput)),
    resetContainerState: () => dispatch(resetContainerState())
});

export default connect(mapStateToProps, mapDispatchToProps) (Identification);