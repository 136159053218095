import React from 'react';
import { ScanIcons } from 'src/constants/general-media';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import Icon from '@amzn/meridian/icon';
import mergeTokens from '@amzn/meridian-tokens/base/icon/merge';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import { MoveContainerMode } from 'src/models/batchMoveContainers';
import StatusAlert from 'src/components/common/status-alert/StatusAlertContainer';
import UILoader from 'src/components/common/loader/UILoaderContainer';
import { BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';
import SingleChildContainersScanPage
    from 'src/components/pages/child-container-scan/single-mode/SingleChildContainerScanPageContainer';
import DestinationContainerScanPage
    from 'src/components/pages/destination-container-scan/DestinationContainerScanPageContainer';
import CloseContainerPage from 'src/components/pages/close-container-page/CloseContainerPageContainer';
import PrintLabelPage from 'src/components/pages/close-container-page/print-label-page/PrintLabelPageContainer';

interface SingleContainerStateProps {
    pageToRender: BatchMoveContainersPages;
}

interface SingleContainerDispatchProps {
    resetContainerState: () => void;
}

type Props = SingleContainerStateProps & SingleContainerDispatchProps;

class SingleContainer extends React.Component<Props> {

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="single-container">

                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_CHILD_CONTAINERS &&
                            <SingleChildContainersScanPage
                                barcodeScanIcon={ScanIcons.SCAN_HANDHELD_ICON}
                                barcodeScanText={AppStrings.CONTAINERIZATION_MAIN_TEXT}
                                barcodeScanInputLabel={AppStrings.CONTAINERIZATION_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.CONTAINERIZATION_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.CONTAINERIZATION_BUTTON}
                                barcodeScanButtonIcon={<Icon tokens={mergeTokens}/>}
                            />
                        }

                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_DESTINATION_CONTAINER &&
                            <DestinationContainerScanPage
                                pageTitle={AppStrings.DESTINATION_CONTAINER_MAIN_TEXT}
                                barcodeScanIcon={ScanIcons.SCAN_PALETTE_ICON}
                                barcodeScanText={AppStrings.DESTINATION_SCAN_TEXT}
                                barcodeScanInputLabel={AppStrings.DESTINATION_SCAN_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.DESTINATION_SCAN_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.CONTAINERIZATION_BUTTON}
                                barcodeScanButtonIcon={<Icon tokens={mergeTokens}/>}
                                moveContainerMode={MoveContainerMode.CONTAINERIZE}
                            />
                        }

                        {this.props.pageToRender === BatchMoveContainersPages.CLOSE_CONTAINER &&
                            <CloseContainerPage
                                pageTitle={AppStrings.CLOSE_CONTAINER}
                            />
                        }

                        {this.props.pageToRender === BatchMoveContainersPages.PRINT_LABEL &&
                            <PrintLabelPage />
                        }

                        <UILoader />
                        <StatusAlert />

                    </Column>
                )}
            </Responsive>
        )
    }

    componentWillUnmount() {
        this.props.resetContainerState();
    }
}

export default SingleContainer;
