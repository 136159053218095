import React, {PureComponent} from 'react';
import Table, { TableRow, TableCell, TableActionBar} from '@amzn/meridian/table';
import { Thumbnails } from 'src/constants/general-media';
import Text from '@amzn/meridian/text';
import * as AppStrings from 'src/localization/string-ids/app-strings';

import './ContainerLabelDetails.scss';
import {ContainerDetailsModel, ContainerType} from "src/components/common/container-details/ContainerDetailsModel";

export interface ContainerLabelDetailsProps {
    containerLabelDetails: ContainerDetailsModel;
}

class ContainerLabelDetails extends PureComponent<ContainerLabelDetailsProps> {

    private getThumbnailFromContainerType = (containerType: ContainerType): string => {
        switch (containerType) {
            case ContainerType.PALLET:
                return Thumbnails.PALLET_THUMBNAIL;
            default:
                return '';
        }
    }

    private getContainerLabelTypeFromContainerType = (containerType: ContainerType): string => {
        switch (containerType) {
            case ContainerType.PALLET:
                return AppStrings.CONTAINER_DETAILS_PALLET_ID;
            default:
                return '';
        }
    }

    private getChildContainersTypeFromContainerType = (containerType: ContainerType): string => {
        switch (containerType) {
            case ContainerType.STACKING_AREA:
                return AppStrings.CONTAINER_DETAILS_TOTES;
            case ContainerType.PALLET:
                return AppStrings.CONTAINER_DETAILS_TOTES;
            case ContainerType.TOTE:
                return AppStrings.CONTAINER_DETAILS_ITEMS;
            default:
                return 'numOfChildContainers:';
        }
    }

    // building rows for sortation categories in the container label view
    private getTableRowsForList = (key:string, value?:string[]): React.ReactElement[] => {
        const rows: React.ReactElement[] = [];
        if (!value)
            return rows;

        rows.push(
            <TableRow key={key}>
                <TableCell rowSpan={value.length}>{AppStrings.CONTAINER_DETAILS_SORTATION_CATEGORIES}</TableCell>
                <TableCell header={true}>{value[0]}</TableCell>
                <TableCell/>
            </TableRow>
        );
        for (let i = 1; i < value.length; i++) {
            rows.push(
                <TableRow key={`${key}-${i}`}>
                    <TableCell header={true}>{value[i]}</TableCell>
                    <TableCell/>
                </TableRow>
            );
        }

        return rows;
    }

    public render() {
        const {containerLabelDetails} = this.props;
        return (
            <Table spacing="small" nestedRows={true} className="table-view" data-testid="container-label-details-table" >
                <TableActionBar>
                    <Text type="b300">{AppStrings.CONTAINER_DETAILS_ACTION_BAR_RESULT}</Text>
                    <Text type="h200">{containerLabelDetails.containerId}</Text>
                </TableActionBar>

                <TableRow>
                    <TableCell>
                        <div className="container-label-div">
                            <img src={this.getThumbnailFromContainerType(containerLabelDetails.containerType as ContainerType)}
                                 alt={''} className="container-label-thumbnail"/>
                            <Text className="container-label-id">{this.getContainerLabelTypeFromContainerType(containerLabelDetails.containerType as ContainerType)}</Text>
                        </div>
                    </TableCell>
                    <TableCell header={true}>{containerLabelDetails["containerId"]}</TableCell>
                    <TableCell />
                </TableRow>


                {this.getTableRowsForList("sortationCategories", containerLabelDetails["sortationCategories"])}

                <TableRow>
                    <TableCell>{AppStrings.CONTAINER_DETAILS_LATEST_PROCESSING_DATE}</TableCell>
                    <TableCell header={true}>{containerLabelDetails["latestProcessingDate"]}</TableCell>
                    <TableCell />
                </TableRow>

                <TableRow>
                    <TableCell>{this.getChildContainersTypeFromContainerType(containerLabelDetails.containerType as ContainerType)}</TableCell>
                    <TableCell header={true}>{containerLabelDetails.numOfChildContainers}</TableCell>
                    <TableCell />
                </TableRow>

            </Table>
        )
    }
}

export default ContainerLabelDetails;
