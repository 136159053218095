import axios, {AxiosError} from 'axios';
import { AUTHENTICATION_FAILED_MESSAGE, PERMISSIONS_DENIED_MESSAGE } from 'src/localization/string-ids/app-strings';


class BackendServiceCall {
    private static DOMAIN_NAME = window.location.protocol + "//" + window.location.hostname;
    static async makeApiRequest<T extends object>(input: T, url: string, methodType: string) {
        const response = await this.callAxios(this.buildRequest(url, methodType, input));
        return response;
    }

    /**
     * It is a wrapper around axios that abstracts out implementation details of calling APIs via axios.
     *
     *
     */
    static callAxios = async (input: any) => {
        try {
            const response = await axios.request(input);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const { status, data } = error.response || {};
                if (status === 500 && data === AUTHENTICATION_FAILED_MESSAGE) {
                    throw AUTHENTICATION_FAILED_MESSAGE;
                } else if (status === 500 && data === PERMISSIONS_DENIED_MESSAGE) {
                    throw PERMISSIONS_DENIED_MESSAGE;
                } else if (status === 400 && error.response != null) {
                    return Promise.reject(new Error(error.response.data));
                } else {
                    return Promise.reject(new Error('Error occurred in API call: ' + error));
                }
            } else {
                return Promise.reject(new Error('Error occurred in API call: ' + error));
            }
        }
    }

    static buildRequest = (url: string, method: string, body: object | string | null) => {
        url = this.DOMAIN_NAME + url;

        const requestConfig: any = {
            headers: { 'Content-Type': 'application/json' },
            method: method,
            url: url,
        };

        if (method.toUpperCase() === 'POST') {
            requestConfig.data = body;  // POST requests use `data` for the payload
        } else {
            requestConfig.params = body; // GET requests use `params` for the payload
        }
        return requestConfig;
    }
}

export default BackendServiceCall;