import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import { SendToProcessPages } from 'src/store/common-slices/processContainerSlice';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import Button from '@amzn/meridian/button';
import ContainerDetails from 'src/components/common/container-details/ContainerDetails';
import Icon from '@amzn/meridian/icon';
import shareTokens from '@amzn/meridian-tokens/base/icon/share';
import { buildProcessContainerInput, ProcessContainerInput } from 'src/models/processContainer';
import { UIAlertState } from 'src/store/common-slices/uiSlice';
import { AlertLevels } from 'src/models/alert-model';
import '../SendToProcess.scss'

interface ConfirmSendToProcessPageStateProps {
    containerDetails?: ContainerDetailsModel;
    uiAlertState: UIAlertState;
    warehouseId: string,
    associate: string

}

interface ConfirmSendToProcessPageDispatchProps {
    setPageToRender: (pageToRender: SendToProcessPages) => void;
    processContainer: (processContainerInput: ProcessContainerInput) => void;
}

type Props = ConfirmSendToProcessPageStateProps & ConfirmSendToProcessPageDispatchProps;

class ConfirmSendToProcessPage extends React.Component<Props, any> {

    private onSubmit = async () => {
        if (this.props.containerDetails) {
            await this.props.processContainer(buildProcessContainerInput(this.props.containerDetails.containerId,
                this.props.warehouseId, this.props.associate))
        }
        if (this.props.uiAlertState.alertLevel === AlertLevels.SUCCESS) {
            await this.props.setPageToRender(SendToProcessPages.SCAN_CONTAINER_PAGE)
        }
    }
    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column>
                        {this.props.containerDetails &&
                            <ContainerDetails containerDetails={this.props.containerDetails}/>
                        }
                        <Button size={props.buttonSize} onClick={this.onSubmit}>
                            {AppStrings.SEND_TO_PROCESS_BUTTON}
                            {<Icon tokens={shareTokens} />}
                        </Button>

                    </Column>
                )}
            </Responsive>
        );
    }
}

export default ConfirmSendToProcessPage;