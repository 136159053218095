import React from 'react';
import SearchField from '@amzn/meridian/search-field';
import { Icons } from 'src/constants/general-media';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Text from '@amzn/meridian/text';
import * as AppStrings from 'src/localization/string-ids/app-strings';

import './HomePage.scss';

interface HomePageProps {
    accessibleTools: string[];
}

interface HomePageState {
    searchFieldValue: string
}

class HomePage extends React.Component<HomePageProps, HomePageState> {
    constructor(props: HomePageProps) {
        super(props);
        this.state = {
            searchFieldValue: ''
        };
    }

    setSearchValue = (value: string): void => {
        this.setState({
            searchFieldValue: value
        });
    }

    onSearchSubmit = () => {
    };

    private SEARCH = "Search";

    private shouldToolBeVisible = (toolName: string): boolean => {
        if(this.props.accessibleTools.includes(toolName)) {
            return true;
        }
        return false;
    }
    
    public render() {
        const {searchFieldValue} = this.state;
        return (
            <Responsive query={"max-height"} props={responsiveProps}>
                {props => (
                    <div className="homepage">
                        <img src={Icons.RIV_ICON_MAIN} alt="RIV main Icon"/>
                        {!this.shouldToolBeVisible(this.SEARCH) ? null :
                            <div>
                                <img src={Icons.RIV_SEARCH_GROUP_ICON} alt="RIV search group Icon" />
                                <Text type={props.titleSize} alignment="center">
                                    {AppStrings.HOMEPAGE_CENTER_TEXT}
                                </Text>
                                <div className="search-field-div">
                                    <SearchField
                                        type="text"
                                        value={searchFieldValue}
                                        onChange={this.setSearchValue}
                                        onSubmit={this.onSearchSubmit}
                                        size="xlarge"
                                        label={AppStrings.HOMEPAGE_SEARCHFIELD_LABEL}
                                        placeholder={AppStrings.HOMEPAGE_SEARCHFIELD_PLACEHOLDER}
                                        searchButton={true}
                                        inaccessibleAutoFocus={true} />
                                </div>
                            </div>
                        }
                    </div>
                )}
            </Responsive>
        );
    }
}

export default HomePage;

