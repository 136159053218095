import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import SendToProcess from './SendToProcess';
import { resetProcessContainerState } from 'src/store/common-slices/processContainerSlice';
import { resetContainerState } from 'src/store/common-slices/getContainerSlice';

const mapStateToProps = (state: RootState) => ({
    pageToRender: state.ProcessContainer.pageToRender
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetProcessContainerState: () => dispatch(resetProcessContainerState()),
    resetContainerState: () => dispatch(resetContainerState())
});

export default connect(mapStateToProps, mapDispatchToProps)(SendToProcess);