import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'src/components/app/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from 'src/store/store';

import './index.scss';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import { fetchInitResponse } from './store/common-slices/initSlice';
import PermissionsDeniedPage from './PermissionsDeniedPage';
import { InitRequest } from './models/init-model';
import UserUnAuthenticatedPage from './UserUnAuthenticatedPage';
import { AUTHENTICATION_FAILED_MESSAGE, PERMISSIONS_DENIED_MESSAGE } from './localization/string-ids/app-strings';


const FCMENU_SENSITIVE = "fcmenu-sensitive";
 
export const buildInitRequest = (): InitRequest => {
        //Following FCMenu onboarding wiki Option 2 for domain name
        // ending in .amazon.dev
        //https://w.amazon.com/bin/view/AFT/Identity/FCMenu/Onboard/
        const search = window.location.search;
        let sensitiveCookie = new URLSearchParams(search).get(FCMENU_SENSITIVE);
        if(sensitiveCookie === null) {
          // Sending empty cookie for null as this case is handled in backend
          // Need to handle this case becasue token value can be null or string
          // It is giving type safety error if we are just passing sensitiveCookie.
          sensitiveCookie = ''; 
        }
        return {
          tokenName: 'fcmenu-sensitive',
          tokenValue: sensitiveCookie,
        }
}

async function initializeApp() {
    await store.dispatch(fetchInitResponse(buildInitRequest()));
    const currentState = store.getState();
    if(currentState.Init.error === AUTHENTICATION_FAILED_MESSAGE) {
      return { isUnauthenticated: true };
    } else if (currentState.Init.error === PERMISSIONS_DENIED_MESSAGE) {
      return { isUnauthenticated: false, isUnauthorized: true };
    }
    return { isUnauthenticated: false, isUnauthorized: false };
}
 
initializeApp().then(({ isUnauthenticated, isUnauthorized }) => {
  const root : ReactDOM.Root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  let contentToRender;
  /*TODO: Is there a standard authentication failed or authorization failed page
  provided by FCMenu or FCLM which can be used here?*/
  if (isUnauthenticated) {
    contentToRender = <UserUnAuthenticatedPage />;
  } else if (isUnauthorized) {
    contentToRender = <PermissionsDeniedPage />;
  } else {
    contentToRender = <App />;
  }
  root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
            {contentToRender}
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

