import React from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Input from '@amzn/meridian/input';
import Button from '@amzn/meridian/button';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import { KeyboardKeys } from 'src/constants/keyboard-constants';
import { isBlankString, isNotBlankString } from 'src/utils/string-utils';
import './BarcodeScan.scss'

interface BarcodeScanProps {
    shouldRenderImageOrIcon: boolean;
    scanImageOrIcon?: string;
    scanImageAltText?: string;
    shouldRenderText: boolean;
    scanText?: string;
    inputLabel: string;
    inputPlaceholder?: string;
    shouldRenderButton: boolean;
    buttonText?: string;
    buttonIcon?: React.ReactElement;
    onSubmit: (inputValue: string) => void;
}

interface BarcodeScanState {
    inputValue: string;
}
class BarcodeScan extends React.Component<BarcodeScanProps, BarcodeScanState> {
    constructor(props: BarcodeScanProps) {
        super(props);
        this.state = this.getInitialState();
    }

    private getInitialState = (): BarcodeScanState => {
        return {
            inputValue: ''
        }
    }

    private onInputChange = (value: string) => {
        this.setState({
            inputValue: value
        })
    }

    private onKeyDown = (event: KeyboardEvent): void => {
        if (event.key === KeyboardKeys.ENTER && isNotBlankString(this.state.inputValue)) {
            this.submitInput();
        }
    }

    private submitInput = () => {
        this.props.onSubmit(this.state.inputValue);
        this.setState(this.getInitialState());
    }

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="barcode-scan">
                        {this.props.shouldRenderImageOrIcon &&
                            <img className="barcode-scan-icon" src={this.props.scanImageOrIcon} alt={this.props.scanImageAltText}/>
                        }
                        {this.props.shouldRenderText && <Text type={props.titleSize} alignment="center">{this.props.scanText}</Text>}
                        <Input
                            label={this.props.inputLabel}
                            placeholder={this.props.inputPlaceholder}
                            inaccessibleAutoFocus={true}
                            size={props.inputBarSize}
                            value={this.state.inputValue}
                            onChange={this.onInputChange}
                            onKeyDown={this.onKeyDown}
                        />
                        {this.props.shouldRenderButton &&
                            <Button size={props.buttonSize} disabled={isBlankString(this.state.inputValue)} onClick={this.submitInput}>
                                {this.props.buttonText}
                                {this.props.buttonIcon}
                            </Button>
                        }
                    </Column>
                )}
            </Responsive>
        )
    }
}

export default BarcodeScan;