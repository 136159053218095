type ContainerId = string;

export enum ContainerType {
    ITEM = 'ITEM',
    TOTE = 'TOTE',
    PALLET = 'PALLET',
    STACKING_AREA = 'STACKING_AREA',
    DROP_ZONE = 'DROP_ZONE',
    TRAILER = 'TRAILER'
}

type WarehouseId = string;

type SortationCategories = string[];

interface ParentContainer {
    containerId: ContainerId,
    containerType?: ContainerType
}

type LatestProcessingDate = string;

type ContainerProperties = { [key:string]: string };

type OperationName = string;

type OperationTime = string;

type AssociateLogin = string;

type NumberOfChildContainers = number;

interface ContainerOperation {
    name: OperationName,
    time: OperationTime,
    associate: AssociateLogin
}

export interface ContainerDetailsModel {
    containerId: ContainerId,
    containerType?: ContainerType,
    warehouseId?: WarehouseId,
    sortationCategories?: SortationCategories,
    parentContainer?: ParentContainer,
    latestProcessingDate?: LatestProcessingDate,
    containerProperties?: ContainerProperties,
    latestContainerOperation?: ContainerOperation,
    numOfChildContainers?: NumberOfChildContainers
}