import {AppDispatch, RootState} from "src/store/store";
import {connect} from "react-redux";
import Receive from "src/components/pages/receive-page/Receive";
import {ReceiveContainerInput} from "src/models/receiveContainer";
import {receiveContainer} from "src/store/common-slices/receiveContainerSlice";
import {fetchContainerDetails, resetContainerState} from "src/store/common-slices/getContainerSlice";
import {GetContainerDetailsInput} from "src/models/GetContainerDetails";

const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id,
    locale: state.Init.initData.associateDetails.locales[0]
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => dispatch(fetchContainerDetails(getContainerDetailsInput)),
    receiveContainer: (receiveContainerInput: ReceiveContainerInput) => dispatch(receiveContainer(receiveContainerInput)),
    resetContainerState: () => dispatch(resetContainerState())
});

export default connect(mapStateToProps, mapDispatchToProps)(Receive)