import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import { ScanIcons } from 'src/constants/general-media';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import Icon from '@amzn/meridian/icon';
import mergeTokens from '@amzn/meridian-tokens/base/icon/merge';
import UILoader from 'src/components/common/loader/UILoaderContainer';
import StatusAlert from 'src/components/common/status-alert/StatusAlertContainer';
import { BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';
import DestinationContainerScanPage
    from 'src/components/pages/destination-container-scan/DestinationContainerScanPageContainer';
import MultiChildContainersScanPage
    from 'src/components/pages/child-container-scan/multi-mode/MultiChildContainersScanPageContainer';
import { MoveContainerMode } from 'src/models/batchMoveContainers';

interface MultiContainerizationStateProps {
    pageToRender: BatchMoveContainersPages;
}

interface MultiContainerizationDispatchProps {
    resetChildAndDestinationContainers: () => void;
    resetContainerState: () => void;
}

type Props = MultiContainerizationStateProps & MultiContainerizationDispatchProps;

class MultiContainerization extends React.Component<Props> {

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="multi-container">
                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_CHILD_CONTAINERS &&
                            <MultiChildContainersScanPage
                                barcodeScanIcon={ScanIcons.SCAN_HANDHELD_ICON}
                                barcodeScanText={AppStrings.CONTAINERIZATION_MAIN_TEXT}
                                barcodeScanInputLabel={AppStrings.MULTI_CONTAINERIZATION_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.MULTI_CONTAINERIZATION_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.CONTAINERIZATION_BUTTON}
                                barcodeScanButtonIcon={<Icon tokens={mergeTokens}/>}
                                submitChildContainersButtonText={AppStrings.CHILD_CONTAINER_SUBMIT_BUTTON}
                                submitChildContainersButtonIcon={<Icon tokens={mergeTokens}/>}
                            />
                        }
                        {this.props.pageToRender === BatchMoveContainersPages.SCAN_DESTINATION_CONTAINER &&
                            <DestinationContainerScanPage
                                pageTitle={AppStrings.DESTINATION_CONTAINER_MAIN_TEXT}
                                barcodeScanIcon={ScanIcons.SCAN_PALETTE_ICON}
                                barcodeScanText={AppStrings.DESTINATION_SCAN_TEXT}
                                barcodeScanInputLabel={AppStrings.DESTINATION_SCAN_INPUT_LABEL}
                                barcodeScanInputPlaceholder={AppStrings.DESTINATION_SCAN_INPUT_PLACEHOLDER}
                                barcodeScanButtonText={AppStrings.CONTAINERIZATION_BUTTON}
                                barcodeScanButtonIcon={<Icon tokens={mergeTokens}/>}
                                moveContainerMode={MoveContainerMode.CONTAINERIZE}
                            />
                        }
                        <UILoader />
                        <StatusAlert />
                    </Column>
                )}
            </Responsive>
        );
    }

    componentWillUnmount() {
        this.props.resetContainerState();
        this.props.resetChildAndDestinationContainers();
    }
}

export default MultiContainerization;
