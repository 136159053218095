import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import ButtonGroup, {ButtonOption} from '@amzn/meridian/button-group';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import BarcodeScan from 'src/components/common/barcode-scan/BarcodeScan';
import { buildGetContainerDetailsInput, GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import { ContainerDetailsModel, ContainerType } from 'src/components/common/container-details/ContainerDetailsModel';
import { BatchMoveContainersMode, BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface SingleChildContainersScanPageProps {
    barcodeScanIcon: string;
    barcodeScanText: string;
    barcodeScanInputLabel: string;
    barcodeScanInputPlaceholder: string;
    barcodeScanButtonText: string;
    barcodeScanButtonIcon: React.ReactElement;
    warehouseId: string,
    associate: string,
    locale: string
}

interface SingleChildContainersScanStateProps {
    containerDetails?: ContainerDetailsModel;
    batchMoveMode: BatchMoveContainersMode;
}

interface SingleChildContainersScanDispatchProps {
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => void;
    updateChildContainers: (newContainer: ContainerDetailsModel) => void;
    setBatchMoveMode: (batchMoveMode: BatchMoveContainersMode) => void;
    setPageToRender: (pageToRender: BatchMoveContainersPages) => void;
    resetChildAndDestinationContainers: () => void;
}

type Props = SingleChildContainersScanPageProps & SingleChildContainersScanStateProps & SingleChildContainersScanDispatchProps;

class SingleChildContainersScanPage extends React.Component<Props> {

    private onSubmit = async (containerId: string) => {
        await this.props.fetchContainerDetails(buildGetContainerDetailsInput(containerId, 
            this.props.warehouseId, this.props.associate, this.props.locale));
        if (this.props.containerDetails) {
            await this.props.updateChildContainers(this.props.containerDetails);
            if (this.props.containerDetails.containerType === ContainerType.STACKING_AREA) {
                this.props.setPageToRender(BatchMoveContainersPages.CLOSE_CONTAINER);
            } else {
                this.props.setPageToRender(BatchMoveContainersPages.SCAN_DESTINATION_CONTAINER);
            }
        }
    }

    componentDidMount() {
        this.props.resetChildAndDestinationContainers();
    }

    public render() {
        return(
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="single-child-containers-page">
                        <ButtonGroup size={props.buttonGroupSize} type="outline" value={this.props.batchMoveMode}>
                            <ButtonOption
                                value={BatchMoveContainersMode.SINGLE_MODE}
                                onClick={() => this.props.setBatchMoveMode(BatchMoveContainersMode.SINGLE_MODE)}
                                data-testid="single-mode-button"
                            >
                                {AppStrings.SINGLE_CONTAINER_MODE}
                            </ButtonOption>
                            <ButtonOption
                                value={BatchMoveContainersMode.MULTI_MODE}
                                onClick={() => this.props.setBatchMoveMode(BatchMoveContainersMode.MULTI_MODE)}
                                data-testid="multi-mode-button"
                            >
                                {AppStrings.MULTI_CONTAINERS_MODE}
                            </ButtonOption>
                        </ButtonGroup>

                        <BarcodeScan
                            shouldRenderImageOrIcon={true}
                            scanImageOrIcon={this.props.barcodeScanIcon}
                            scanImageAltText="Scan Handheld Icon"
                            shouldRenderText={true}
                            scanText={this.props.barcodeScanText}
                            inputLabel={this.props.barcodeScanInputLabel}
                            inputPlaceholder={this.props.barcodeScanInputPlaceholder}
                            shouldRenderButton={true}
                            buttonText={this.props.barcodeScanButtonText}
                            buttonIcon={this.props.barcodeScanButtonIcon}
                            onSubmit={this.onSubmit}
                        />
                    </Column>
                )}
            </Responsive>
        );
    }
}

export default SingleChildContainersScanPage;