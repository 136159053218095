import React from 'react';
import SearchField from '@amzn/meridian/search-field';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';
import Responsive from '@amzn/meridian/responsive'
import {ContainerDetailsModel} from "src/components/common/container-details/ContainerDetailsModel";
import {buildGetContainerDetailsPrintModeInput, GetContainerDetailsInput} from "src/models/GetContainerDetails";
import {responsiveProps} from "src/constants/responsiveProps";
import * as AppRoutes from 'src/routing/constants/routes';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import {PrintIcons} from "src/constants/general-media";
import UILoader from "src/components/common/loader/UILoaderContainer";
import StatusAlert from "src/components/common/status-alert/StatusAlertContainer";
import ContainerLabelDetails from "src/components/pages/print-page/ContainerLabelDetails";
import './Print.scss';
import {
    buildPrintContainerLabelDetailsInput,
    PrintContainerLabelDetailsInput, PrintContainerLabelDetailsOutput
} from "src/models/PrintContainerLabelDetails";

export interface PrintProps {
    containerLabelDetails?: ContainerDetailsModel;
    warehouseId: string,
    associate: string, 
    locale: string
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => void;
    resetContainerState: () => void;
    printContainerLabel: (printContainerLabelDetailsInput: PrintContainerLabelDetailsInput) => PrintContainerLabelDetailsOutput;
}

export interface PrintState {
    searchFieldValue: string;
    containerId: string;
    searchFieldValuePrint: string;
    printerId: string;
}

class Print extends React.Component<PrintProps, PrintState> {
    constructor(props: PrintProps) {
        super(props);
        this.state = {
            searchFieldValue: '',
            containerId: '',
            searchFieldValuePrint: '',
            printerId: ''
        };
    }

    private setSearchValue = (value: string): void => {
        this.setState({
            searchFieldValue: value
        });
    }

    private setSearchValuePrint = (value: string): void => {
        this.setState({
            searchFieldValuePrint: value
        });
    }

    private onSearchSubmit = () => {
        const {searchFieldValue} = this.state;
        if (searchFieldValue) {
            this.setState({
                containerId: searchFieldValue,
                searchFieldValue: ''
            }, async () => {
                await this.props.fetchContainerDetails(buildGetContainerDetailsPrintModeInput(searchFieldValue, 
                    this.props.warehouseId, this.props.associate, this.props.locale));
            });
        }
    };

    private onSearchSubmitPrint = () => {
        const { searchFieldValuePrint } = this.state;
        if (searchFieldValuePrint) {
            this.setState({
                printerId: searchFieldValuePrint,
                searchFieldValuePrint: ''
            }, async() => {
                if (this.props.containerLabelDetails) {
                    await this.props.printContainerLabel(buildPrintContainerLabelDetailsInput(this.props.containerLabelDetails.containerId, searchFieldValuePrint,
                        this.props.warehouseId, this.props.associate
                    ));
                }
            });
        }
    };

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column className="print">
                        <BreadcrumbGroup>
                            <Breadcrumb href={AppRoutes.HOME_PAGE_ROUTE}>{AppStrings.HOME}</Breadcrumb>
                            <Breadcrumb>{AppStrings.PRINT}</Breadcrumb>
                        </BreadcrumbGroup>

                        {/* first page of print renders print icon and text */}
                        {!this.props.containerLabelDetails &&
                            <Column alignmentHorizontal="center">
                                <img src={PrintIcons.PRINTER_ICON} alt="Print Icon" className="print-icon" />
                                <Text type={props.titleSize}>{AppStrings.PRINT_CENTER_TEXT}</Text>
                            </Column>
                        }

                        {/* first page of print renders search field for entering tote, pallet or stacking area */}
                        {!this.props.containerLabelDetails &&
                            <SearchField
                                inaccessibleAutoFocus={true}
                                type="text"
                                value={this.state.searchFieldValue}
                                onChange={this.setSearchValue}
                                onSubmit={this.onSearchSubmit}
                                size="xlarge"
                                label={AppStrings.PRINT_SEARCHFIELD_LABEL}
                                placeholder={AppStrings.PRINT_SEARCHFIELD_PLACEHOLDER}
                                searchButton={true}
                            />
                        }

                        <UILoader/>
                        <StatusAlert/>

                        {/* second page of print renders search field for entering printer id */}
                        {this.props.containerLabelDetails &&
                            <SearchField
                                inaccessibleAutoFocus={true}
                                type="text"
                                value={this.state.searchFieldValuePrint}
                                onChange={this.setSearchValuePrint}
                                onSubmit={this.onSearchSubmitPrint}
                                size="xlarge"
                                label={AppStrings.PRINTER_SEARCHFIELD_LABEL}
                                placeholder={AppStrings.PRINTER_SEARCHFIELD_PLACEHOLDER}
                                searchButton={true}
                            />
                        }
                        {/* second page of print renders container label details for valid container ID */}
                        {this.props.containerLabelDetails &&
                            <ContainerLabelDetails containerLabelDetails={this.props.containerLabelDetails} />
                        }
                    </Column>
                )}
            </Responsive>
        );
    }
    componentWillUnmount() {
        this.props.resetContainerState();
    }
}

export default Print;