import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import { GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import { fetchContainerDetails } from 'src/store/common-slices/getContainerSlice';
import {
    resetChildAndDestinationContainers,
    updateChildContainers,
    setBatchMoveMode,
    setPageToRender,
    BatchMoveContainersMode,
    BatchMoveContainersPages
} from 'src/store/common-slices/batchMoveContainersSlice';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import SingleChildContainersScanPage from 'src/components/pages/child-container-scan/single-mode/SingleChildContainersScanPage';


const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    batchMoveMode: state.BatchMoveContainers.batchMoveMode,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id,
    locale: state.Init.initData.associateDetails.locales[0]
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => dispatch(fetchContainerDetails(getContainerDetailsInput)),
    updateChildContainers: (newContainer: ContainerDetailsModel) => dispatch(updateChildContainers(newContainer)),
    setBatchMoveMode: (batchMoveMode: BatchMoveContainersMode) => dispatch(setBatchMoveMode(batchMoveMode)),
    setPageToRender: (pageToRender: BatchMoveContainersPages) => dispatch(setPageToRender(pageToRender)),
    resetChildAndDestinationContainers: () => dispatch(resetChildAndDestinationContainers())
});

export default connect(mapStateToProps, mapDispatchToProps) (SingleChildContainersScanPage);