import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import { GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import { fetchContainerDetails } from 'src/store/common-slices/getContainerSlice';
import { setUiAlertState, UIAlertState } from 'src/store/common-slices/uiSlice';
import {
    BatchMoveContainersMode,
    BatchMoveContainersPages,
    setBatchMoveMode,
    updateChildContainers,
    setPageToRender
} from 'src/store/common-slices/batchMoveContainersSlice';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import MultiChildContainersScanPage from './MultiChildContainersScanPage';

const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    childContainers: state.BatchMoveContainers.childContainers,
    batchMoveMode: state.BatchMoveContainers.batchMoveMode,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id,
    locale: state.Init.initData.associateDetails.locales[0]
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => dispatch(fetchContainerDetails(getContainerDetailsInput)),
    setUiAlertState: (alertState: UIAlertState) => dispatch(setUiAlertState(alertState)),
    updateChildContainers: (newContainer: ContainerDetailsModel) => dispatch(updateChildContainers(newContainer)),
    setBatchMoveMode: (batchMoveContainersMode: BatchMoveContainersMode) => dispatch(setBatchMoveMode(batchMoveContainersMode)),
    setPageToRender: (pageToRender: BatchMoveContainersPages) => dispatch(setPageToRender(pageToRender))
});

export default connect(mapStateToProps, mapDispatchToProps) (MultiChildContainersScanPage);