import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import StatusAlert from 'src/components/common/status-alert/StatusAlert';
import { resetUiAlertState } from 'src/store/common-slices/uiSlice';

const mapStateToProps = (state: RootState) => ({
    alertLevel: state.UI.alertState.alertLevel,
    alertTitleString: state.UI.alertState.alertTitleString,
    alertMessageString: state.UI.alertState.alertMessageString,
    alertTimeoutInMs: state.UI.alertState?.alertTimeoutInMs,
    alertSize: state.UI.alertState?.alertSize
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetUiAlertState: () => dispatch(resetUiAlertState())
});

export default connect(mapStateToProps, mapDispatchToProps) (StatusAlert);