import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import SingleMove from './SingleMove';
import { resetContainerState } from 'src/store/common-slices/getContainerSlice';
import { resetChildAndDestinationContainers } from 'src/store/common-slices/batchMoveContainersSlice';

const mapStateToProps = (state: RootState) => ({
    pageToRender: state.BatchMoveContainers.pageToRender
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetContainerState: () => dispatch(resetContainerState()),
    resetChildAndDestinationContainers: () => dispatch(resetChildAndDestinationContainers())
})

export default connect(mapStateToProps, mapDispatchToProps) (SingleMove);