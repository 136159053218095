import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Icon from '@amzn/meridian/icon';
import mergeTokens, {MergeTokens} from '@amzn/meridian-tokens/base/icon/merge';
import Text from '@amzn/meridian/text';
import {MoveIcons} from 'src/constants/general-media';
import shareTokens, {ShareTokens} from '@amzn/meridian-tokens/base/icon/share';
import viewTokens, {ViewTokens} from '@amzn/meridian-tokens/base/icon/view';

import './FooterItem.scss'

export interface FooterItemProps {
    footerItemText: string,
    footerItemTokenIdentifier: string
}

type Props = FooterItemProps;

class FooterItem extends React.Component<Props> {

    footerItemIcon = this.props.footerItemTokenIdentifier==="move"
        ? <img src={MoveIcons.MOVE_DEFAULT_ICON} alt="Move Icon"/>
        : <Icon tokens={this.getFooterItemToken()} color="inverted" />

    public render() {
        return (
            <Responsive query={"max-height"} props={responsiveProps}>
                {props => (
                    <div className="footer-item">
                        {this.footerItemIcon}
                        <Text color="inverted" type={props.footerItemText}>{this.props.footerItemText}</Text>
                    </div>
                )}
            </Responsive>

        )
    }

    private getFooterItemToken(): MergeTokens | ViewTokens | ShareTokens {
        switch (this.props.footerItemTokenIdentifier) {
            case 'container':
                return mergeTokens;
            case 'identification':
                return viewTokens;
            case 'send':
                return shareTokens;
            default:
                return shareTokens;
        }
    }
}

export default FooterItem;