import React from 'react';
import * as AppStrings from 'src/localization/string-ids/app-strings';
import { LOGIN_URL } from './constants/urls';
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";
import './UserUnAuthenticatedPage.scss';


const UserUnAuthenticatedPage: React.FC = () => {
  return(
    <div className="unauthenticated">
        <Text type='h100'>
        {AppStrings.USER_AUTHENTICATION_FAILED}
        </Text> 
        <Text>
        {AppStrings.PLEASE_LOGIN_THROUGH}
          <Link href = {LOGIN_URL}
          >
            {AppStrings.FCMENU}
          </Link>
          {AppStrings.SELECT_APPROPRIATE_TOOL}
        </Text>
    </div>
)};

export default UserUnAuthenticatedPage;