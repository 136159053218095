import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';

export enum MoveContainerMode {
    MOVE = 'MOVE',
    CONTAINERIZE = 'CONTAINERIZE'
}

export interface BatchMoveContainersInput {
    destinationContainer: string;
    movingContainers: ContainerDetailsModel[];
    warehouseId: string;
    associate: string;
    mode: MoveContainerMode;
    locale?: string;
}

// No output
export interface BatchMoveContainersOutput {

}

export const buildBatchMoveContainersInput = (destinationContainer: string, movingContainers: ContainerDetailsModel[], mode: MoveContainerMode,
    warehouseId: string, associate: string
): BatchMoveContainersInput => {
    return {
        destinationContainer: destinationContainer,
        movingContainers: movingContainers,
        // TODO: update these when BFF changes are complete to read associate & warehouseId
        warehouseId: warehouseId,
        associate: associate,
        mode: mode
    }
}