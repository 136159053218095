import {createAsyncThunk} from "@reduxjs/toolkit";
import {PrintContainerLabelDetailsInput, PrintContainerLabelDetailsOutput} from "src/models/PrintContainerLabelDetails";
import {resetIsLoading, resetUiAlertState, setIsLoading, setUiAlertState} from "src/store/common-slices/uiSlice";
import RIVBFFService from "src/services/riv-bff-service";
import {resetContainerState} from "src/store/common-slices/getContainerSlice";
import {AlertLevels} from "src/models/alert-model";
import * as ErrorConstants from "src/localization/string-ids/error-constants";
import {PRINT_SUCCESS} from "src/localization/string-ids/success-constants";
import {resetCloseContainerFlag} from "src/store/common-slices/closeContainerSlice";

export const printContainer = createAsyncThunk<
    PrintContainerLabelDetailsOutput,
    PrintContainerLabelDetailsInput>(
    'print/printContainerLabel',
    async (printContainerLabelDetailsInput: PrintContainerLabelDetailsInput, { dispatch }) => {
        dispatch(resetUiAlertState());
        dispatch(setIsLoading());
        try {
            await RIVBFFService.triggerPrintContainerLabelDetailsAPI(printContainerLabelDetailsInput);
            dispatch(resetIsLoading());
            dispatch(resetContainerState());
            dispatch(setUiAlertState({
                alertLevel: AlertLevels.SUCCESS,
                alertMessageString: PRINT_SUCCESS + `${printContainerLabelDetailsInput.printerIP}`
            }));
            dispatch(resetCloseContainerFlag());
        } catch (error) {
            dispatch(resetIsLoading());
            if (error instanceof Error)
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: error.message
                }));
            else
                dispatch(setUiAlertState({
                    alertLevel: AlertLevels.ERROR,
                    alertMessageString: ErrorConstants.UNKNOWN_ERROR
                }));
        }
    }
);