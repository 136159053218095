import React from 'react';
import Responsive from '@amzn/meridian/responsive';
import { responsiveProps } from 'src/constants/responsiveProps';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import chevronLeftSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-left-small';
import Text from '@amzn/meridian/text';
import MultiContainerDetails from 'src/components/common/multi-container-details/MultiContainerDetails';
import ContainerDetails from 'src/components/common/container-details/ContainerDetails';
import BarcodeScan from 'src/components/common/barcode-scan/BarcodeScan';
import { ContainerDetailsModel } from 'src/components/common/container-details/ContainerDetailsModel';
import {
    BatchMoveContainersInput,
    buildBatchMoveContainersInput,
    MoveContainerMode
} from 'src/models/batchMoveContainers';
import { isNotEmpty } from 'src/utils/object-utils';
import { UIAlertState } from 'src/store/common-slices/uiSlice';
import { AlertLevels } from 'src/models/alert-model';
import { BatchMoveContainersMode, BatchMoveContainersPages } from 'src/store/common-slices/batchMoveContainersSlice';

interface DestinationContainerScanPageProps {
    pageTitle: string;
    barcodeScanIcon: string;
    barcodeScanText: string;
    barcodeScanInputLabel: string;
    barcodeScanInputPlaceholder: string;
    barcodeScanButtonText: string;
    barcodeScanButtonIcon: React.ReactElement;
    moveContainerMode: MoveContainerMode;
}

interface DestinationContainerScanStateProps {
    childContainers: ContainerDetailsModel[];
    destinationContainerId: string;
    batchMoveMode:  BatchMoveContainersMode;
    uiAlertState: UIAlertState;
    warehouseId: string,
    associate: string
}

interface DestinationContainerScanDispatchProps {
    updateDestinationContainerId: (destinationContainerId: string) => void;
    batchMoveContainers: (batchMoveContainersInput: BatchMoveContainersInput) => void;
    setPageToRender: (pageToRender: BatchMoveContainersPages) => void;
    resetChildAndDestinationContainers: () => void;
}

type Props = DestinationContainerScanPageProps & DestinationContainerScanStateProps & DestinationContainerScanDispatchProps;

class DestinationContainerScanPage extends React.Component<Props, {}> {

    private onDestinationSubmit = async (containerId: string) => {
        await this.props.updateDestinationContainerId(containerId);
        await this.props.batchMoveContainers(buildBatchMoveContainersInput(this.props.destinationContainerId, 
            this.props.childContainers, this.props.moveContainerMode,
            this.props.warehouseId, this.props.associate))
        if (this.props.uiAlertState.alertLevel === AlertLevels.SUCCESS) {
            this.props.setPageToRender(BatchMoveContainersPages.SCAN_CHILD_CONTAINERS);
            this.props.resetChildAndDestinationContainers();
        }
    }

    public render() {
        return (
            <Responsive query={"max-width"} props={responsiveProps}>
                {props => (
                    <Column data-testid="destination-container-scan-page">
                        <Row>
                            {/*Back button to move back to child container scan page*/}
                            <Button
                                type={"secondary"}
                                onClick={() => this.props.setPageToRender(BatchMoveContainersPages.SCAN_CHILD_CONTAINERS)}
                                data-testid="back-button"
                                circular={true}
                            >
                                <Icon tokens={chevronLeftSmallTokens} />
                            </Button>
                            <Text type={props.titleSize}>{this.props.pageTitle}</Text>
                        </Row>

                        {isNotEmpty(this.props.childContainers) && (
                            this.props.batchMoveMode === BatchMoveContainersMode.SINGLE_MODE
                                ? <ContainerDetails containerDetails={this.props.childContainers[0]}/>
                                : <MultiContainerDetails childContainers={this.props.childContainers}/>
                        )}

                        <BarcodeScan
                            shouldRenderImageOrIcon={true}
                            scanImageOrIcon={this.props.barcodeScanIcon}
                            scanImageAltText={"Destination scan Icon"}
                            shouldRenderText={true}
                            scanText={this.props.barcodeScanText}
                            inputLabel={this.props.barcodeScanInputLabel}
                            inputPlaceholder={this.props.barcodeScanInputPlaceholder}
                            shouldRenderButton={true}
                            buttonText={this.props.barcodeScanButtonText}
                            buttonIcon={this.props.barcodeScanButtonIcon}
                            onSubmit={this.onDestinationSubmit}
                        />
                    </Column>
                )}
            </Responsive>
        )
    }
}

export default DestinationContainerScanPage;