export interface PrintContainerLabelDetailsInput {
    containerId: string;
    printerIP: string;
    warehouseId: string;
    associate: string;
    locale?: string;
}

export interface PrintContainerLabelDetailsOutput {
}

export const buildPrintContainerLabelDetailsInput = (containerId: string,printerIP: string,
    warehouseId: string, associate:string
): PrintContainerLabelDetailsInput => {
    return {
        containerId: containerId,
        printerIP: printerIP,
        // TODO: update these when BFF changes are complete to read associate & warehouseId
        warehouseId: warehouseId,
        associate: associate
    }

}