import { AppDispatch, RootState } from 'src/store/store';
import { fetchContainerDetails, resetContainerState } from 'src/store/common-slices/getContainerSlice';
import { connect } from 'react-redux';
import Print from "./Print";
import { GetContainerDetailsInput } from "src/models/GetContainerDetails";
import { PrintContainerLabelDetailsInput } from "src/models/PrintContainerLabelDetails";
import { printContainer } from "src/store/common-slices/printContainerSlice";


const mapStateToProps = (state: RootState) => ({
    containerLabelDetails: state.GetContainer.containerDetails,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id,
    locale: state.Init.initData.associateDetails.locales[0]
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => dispatch(fetchContainerDetails(getContainerDetailsInput)),
    resetContainerState: () => dispatch(resetContainerState()),
    printContainerLabel: (printContainerLabelDetailsInput: PrintContainerLabelDetailsInput) => dispatch(printContainer(printContainerLabelDetailsInput))
});
export default connect(mapStateToProps, mapDispatchToProps) (Print);