import { AppDispatch, RootState } from 'src/store/store';
import { connect } from 'react-redux';
import ScanContainerPage from './ScanContainerPage';
import { GetContainerDetailsInput } from 'src/models/GetContainerDetails';
import { fetchContainerDetails } from 'src/store/common-slices/getContainerSlice';
import { SendToProcessPages, setPageToRender } from 'src/store/common-slices/processContainerSlice';

const mapStateToProps = (state: RootState) => ({
    containerDetails: state.GetContainer.containerDetails,
    warehouseId: state.Init.initData.gradingNodeDetails.id,
    associate: state.Init.initData.associateDetails.id,
    locale: state.Init.initData.associateDetails.locales[0]
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchContainerDetails: (getContainerDetailsInput: GetContainerDetailsInput) => dispatch(fetchContainerDetails(getContainerDetailsInput)),
    setPageToRender: (pageToRender: SendToProcessPages) => dispatch(setPageToRender(pageToRender))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanContainerPage);