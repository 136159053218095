
const BREAKPOINT_SCANNER = '500px'
const BREAKPOINT_THIN_CLIENT = '720px';
const BREAKPOINT_LAPTOP = '1480px'

export const responsiveProps = {
    titleSize: {
        default: "d50",
        [BREAKPOINT_THIN_CLIENT]: "b500",
        [BREAKPOINT_SCANNER]: "b400"
    },
    footerItemText: {
        default: "b300",
        [BREAKPOINT_SCANNER]: "b200"
    },
    isScanner: {
        default: false,
        [BREAKPOINT_SCANNER]: true
    },
    inputBarSize: {
        default: "xlarge",
        [BREAKPOINT_SCANNER]: "small"
    },
    buttonSize: {
        default: "medium",
        [BREAKPOINT_SCANNER]: "small"
    },
    alertSize: {
        default: "large",
        [BREAKPOINT_SCANNER]: "large"
    },
    buttonGroupSize: {
        default: "medium",
        [BREAKPOINT_SCANNER]: "small"
    }
}